import { Button, Grid, MenuItem, Pagination as MuiPagination, TextField, Tooltip, Typography } from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import { Form } from "react-bootstrap";
import filterLogo from "../../assets/images/Group.png";
import axios from "axios";
import ScaleLoader from "react-spinners/ScaleLoader";
import "./css/MatchedJobs.scss";
import Select from "react-select";
import { Degree } from "../data";
import { AVAILABILITY } from "../data";
import { IoChatbubbleOutline } from "react-icons/io5";
import { Navigate, useNavigate } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { IoChatboxOutline } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loadUser } from "../../actions/userActions";
import Popup from "reactjs-popup";
import { RxCross1 } from "react-icons/rx";
import Pagination from "../Student_New/Pagination";
import { useLocation } from "react-router-dom";
import PaginationWithTooltip from "../Student_New/PaginationWithTooltip";
import { logEvent } from "../../utils/Analytics";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const truncateWithTooltip = (text, length) => {
    if (!text) return "Not available";
    return text.length > length ? (
        <Tooltip 
            title={
                <Typography sx={{ 
                    fontSize: "14px",
                    padding: "4px 8px",
                    whiteSpace: "normal",
                    maxWidth: "300px"
                }}>
                    {text}
                </Typography>
            }
            arrow
        >
            <span>{text.slice(0, length)}...</span>
        </Tooltip>
    ) : (
        text
    );
};
function CandidateDetail() {
    const skillsMapping = {
        "generative ai specialists": ["generative ai"],
        "data entry specialists": ["data entry"],
        "video editors": ["video editing"],
        "data analyst": ["data analysis"],
        "shopify developer": ["shopify"],
        "ruby on rails developer": ["ruby on rails"],
        "android developer": ["android"],
        bookkeeper: ["bookkeeping"],
        "content writer": ["content writing"],
        copywriter: ["copywriting"],
        "data scientist": ["data science"],
        "front-end developer": ["frontend"],
        "game developer": ["game development"],
        "graphic designer": ["graphic design"],
        "ios developer": ["ios"],
        "java developer": ["java"],
        "javascript developer": ["javascript"],
        "logo designer": ["logo design"],
        "mobile app developer": ["mobile apps"],
        "php developer": ["php"],
        "python developer": ["python"],
        "resume writer": ["resume writing"],
        "seo expert": ["seo"],
        "social media manager": ["social media"],
        "software developer": ["software development"],
        "software engineer": ["software engineering"],
        "technical writer": ["technical writing"],
        "ui designer": ["ui design"],
        "ux designer": ["ux design"],
        "virtual assistant": ["virtual assistance"],
        "web designer": ["web design"],
        "wordpress developer": ["wordpress"],
        "prompt engineering specialists": ["prompt engineering"],
        "ai content creators": ["ai content"],
        "chatgpt specialists": ["chatgpt"],
        "ai chatbot specialists": ["ai chatbot"],
        "stable diffusion artists": ["stable diffusion"],
        blockchain: ["blockchain"],
        "go development": ["go"],
        "node.js": ["node.js"],
        "vue.js": ["vuejs"],
        "hr consulting": ["hr"],
        "microsoft power bi": ["power bi"],
        "instructional design": ["instructional design"],
        "react.js": ["react"],
        videographers: ["video editing"],
        "html5 developers": ["html"],
        ghostwriters: ["ghostwriting"],
        "unity 3d developers": ["unity 3d"],
        "business consultants": ["business consulting"],
        coders: ["coding"],
        "web developers": ["web development"],
        illustrators: ["illustration"],
        "google adwords experts": ["adwords"],
        "digital marketers": ["digital marketing"],
        "project managers": ["project management"],
        "ruby developers": ["ruby"],
        "angularjs developers": ["angularjs"],
        "full stack developers": ["full stack"],
        "react native developers": ["react native"],
        "swift developers": ["swift"],
        "css developers": ["css"],
        "back end developers": ["backend"],
    };
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderRadius: "5px",
            paddingTop: "3px",
            paddingBottom: "3px",
            maxWidth: "275px",
            width: "20vw",
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            display: "none",
            borderLeft: "none",
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            display: "none",
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "#ECFFFF" : "white", // Change hover color here
            color: state.isFocused ? "#3498DB" : "black", // Change hover color here
            "&:hover": {
                backgroundColor: "#ECFFFF", // Change hover color here
                color: "#3498DB",
            },
        }),
    };

    const customStylesOne = {
        control: (provided, state) => ({
            ...provided,
            borderRadius: "5px",
            paddingTop: "3px",
            paddingBottom: "3px",
            minWidth: "200px",
            maxWidth: "275px",
            width: "100%",
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            display: "none",
            borderLeft: "none",
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            display: "none",
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "#ECFFFF" : "white", // Change hover color here
            color: state.isFocused ? "#3498DB" : "black", // Change hover color here
            "&:hover": {
                backgroundColor: "#ECFFFF", // Change hover color here
                color: "#3498DB",
            },
        }),
    };
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const { user } = useSelector((state) => state.user);
    const [showModal, setShowModal] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState({});

    const location = useLocation();

    const [filteredSkills, setFilteredSkills] = useState([]);

    useEffect(() => {
        window.scroll(0, 0);
    }, []);
    // console.log(selectedSkill);
    useEffect(() => {
        // Parse skills from URL
        const searchParams = new URLSearchParams(location.search);
        const skillsParam = searchParams.get("skills");

        if (skillsParam) {
            const decodedSkills = skillsParam.split(",").map((skill) => ({
                label: decodeURIComponent(skill),
                value: decodeURIComponent(skill),
            }));

            setFilteredSkills(decodedSkills);
        }
    }, [location.search]);

    console.log(filteredSkills);

    useEffect(() => {
        dispatch(loadUser());
    }, []);

    const [once, setOnce] = useState(false);

    const clearSearch = async () => {
        setSearch({
            ...search,
            profile: "",
            availability: "",
            jobType: [],
            skills: [],
            jobType: [],
        });

        setOnce(true);
        setIsInitialLoad(false);
        navigate("/freelancer-details");
        setFilteredSkills([]);
    };

    useEffect(() => {
        if (once) {
            getData();
            setOnce(false);
        }
    });

    const [profiles, setProfiles] = useState([]);
    const [pdskills, setPdskills] = useState([]);
    const [searchTerm2, setSearchTerm2] = useState("");

    const [filteredProfiles, setFilteredProfiles] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [isTyping1, setIsTyping1] = useState(false);
    const [totalpages, settotalpages] = useState(1);

    useEffect(() => {
        async function getAllProfiles() {
            const response = await axios.get("/api/data/profiles");
            const data = await response.data;
            if (data) {
                setProfiles(data);
            }
        }

        getAllProfiles();
    }, []);
    const filterOptions = (inputValue) => {
        return profiles?.filter((profile) => profile.toLowerCase().includes(inputValue.toLowerCase()));
    };
    useEffect(() => {
        async function fetchAllSkills() {
            if (searchTerm2.trim()) {
                try {
                    const response = await axios.get(`/api/data/skills`, {
                        params: { keyword: searchTerm2 },
                    });
                    if (Array.isArray(response.data)) {
                        setPdskills(response.data);
                    } else {
                        console.error("Unexpected response format:", response.data);
                    }
                } catch (error) {
                    console.error("Error fetching skills:", error);
                }
            } else {
                setPdskills([]);
            }
        }
        fetchAllSkills();
    }, [searchTerm2]);
    const [isTyping, setIsTyping] = useState(false);
    const handleInputChange2 = (newValue) => {
        setSearchTerm2(newValue);
        setIsTyping(newValue.length > 0);
    };
    const [currentPage, setCurrentPage] = useState(1);
    const [teamData, setTeamData] = useState("");
    const [search, setSearch] = useState({
        jobType: [],
        skills: [],
        profile: "",
        availability: "",
        isAutoSearchTriggered: false, // New flag to control automatic searching
    });

    const [loading, setLoading] = useState(false);
    const [selectedItem, setSelectedItem] = useState("");
    const [conversationData, setConversationData] = useState("");

    const handleFilter = (e) => {
        const { name, value, type, checked } = e.target;

        if (type === "checkbox") {
            const updatedJobType = [...search.jobType];
            if (checked) {
                updatedJobType.push(name);
            } else {
                const index = updatedJobType.indexOf(name);
                if (index > -1) {
                    updatedJobType.splice(index, 1);
                }
            }

            setSearch((prevSearch) => ({
                ...prevSearch,
                jobType: updatedJobType,
                isAutoSearchTriggered: false, // Reset auto search flag
            }));
        } else {
            setSearch((prevSearch) => ({
                ...prevSearch,
                [name]: value,
                isAutoSearchTriggered: false, // Reset auto search flag
            }));
        }
    };

    const [isInitialLoad, setIsInitialLoad] = useState(true);

    const safeJSONParse = useCallback((str) => {
        try {
            // First, try to parse as-is
            return JSON.parse(str);
        } catch (error) {
            try {
                // If failed, try to parse after replacing single quotes with double quotes
                return JSON.parse(str.replace(/'/g, '"'));
            } catch (innerError) {
                try {
                    // If still fails, try to manually parse
                    // This handles cases like ['Front-End Developer']
                    const cleanStr = str.replace(/^\[|\]$/g, "").trim();
                    const skills = cleanStr.split(",").map((skill) => skill.trim().replace(/^['"]|['"]$/g, ""));
                    return skills;
                } catch (finalError) {
                    console.error("Failed to parse skills:", str);
                    return [];
                }
            }
        }
    }, []);

    // Helper function to find matching skills
    const findMatchingSkills = useCallback((skill) => {
        const skillText = typeof skill === "object" ? skill.value || skill.label || skill : skill;

        const normalizedSkill = String(skillText)
            .toLowerCase()
            .replace(/^['"]|['"]$/g, "")
            .trim();

        // Check if skill exists in mapping
        const mappedSkills = Object.keys(skillsMapping).find((key) => key.toLowerCase() === normalizedSkill);

        // Return mapped skills or original skill
        return mappedSkills ? skillsMapping[mappedSkills] : [normalizedSkill];
    }, []);
    const storedSkills = sessionStorage.getItem("selectedSkills");
    console.log("stored skills on freelancer page", storedSkills);

    // Initial load effect to set skills from session storage
    useEffect(() => {
        if (isInitialLoad) {
            const selectedTopSkills = sessionStorage.getItem("selectedTopSkills");
            const selectedSkills = sessionStorage.getItem("selectedSkills");
            const selectedProfile = sessionStorage.getItem("selectedProfile");
            const selectedCategory = sessionStorage.getItem("selectedCategory");

            const updatedSearch = {
                ...search,
                isAutoSearchTriggered: true, // Enable auto search for navigation
            };

            if (selectedTopSkills) {
                try {
                    const parsedSkills = safeJSONParse(selectedTopSkills);
                    const expandedSkills = parsedSkills.flatMap((skill) => findMatchingSkills(skill));
                    updatedSearch.skills = [...new Set(expandedSkills.filter((skill) => skill && skill.trim()))];
                } catch (error) {
                    console.error("Error processing skills:", error);
                    updatedSearch.skills = [];
                }
            }

            // Handle selectedSkills (from hireworldwide page)
            if (selectedSkills) {
                try {
                    const parsedSkills = JSON.parse(selectedSkills);
                    // Extract only the values from the label/value pairs
                    const skillValues = parsedSkills.map((skill) => skill.value);
                    updatedSearch.skills = skillValues;
                } catch (error) {
                    console.error("Error parsing selectedSkills:", error);
                }
            }

            // Handle profile and category
            if (selectedProfile) {
                updatedSearch.profile = selectedProfile;
            }

            if (selectedCategory) {
                updatedSearch.profile = selectedCategory;
            }

            // Clear session storage
            sessionStorage.removeItem("selectedTopSkills");
            sessionStorage.removeItem("selectedSkills");
            sessionStorage.removeItem("selectedProfile");
            sessionStorage.removeItem("selectedCategory");

            setSearch(updatedSearch);
            setIsInitialLoad(false);
        }
    }, [isInitialLoad]);

    useEffect(() => {
        if (isInitialLoad) return;

        // Condition 1: Automatic search when navigating with pre-selected skills/profile
        if (search.isAutoSearchTriggered) {
            handleSearch();
            console.log("Automatic search triggered");
        }
        // Condition 2: Manual search only when skills length changes and not auto-triggered
        else if (search.skills.length > 0 || search.profile || search.jobType.length > 0 || search.availability) {
            // Do nothing - wait for manual apply
            console.log("Waiting for manual search");
        }
        // Fallback to get data if no filters
        else {
            getData();
        }
    }, [search, isInitialLoad, currentPage]);

    const handleSearch = async () => {
        console.log("Manual search called");
        setCurrentPage(1);

        // Reset auto search flag for subsequent searches
        setSearch((prevSearch) => ({
            ...prevSearch,
            isAutoSearchTriggered: false,
        }));

        await getData();
    };

    const getData = async () => {
        console.log("get data called");

        setLoading(true);
        try {
            const { data } = await axios.post("/api/user/getuserdata", {
                search,
                page: currentPage,
            });
            const { data: users, totalPages } = data;
            settotalpages(totalPages);
            setTeamData(users);
            setLoading(false);
        } catch {
            console.log("Server is not running...");
            setLoading(false);
        }
    };

    const getConversationData = async () => {
        try {
            const id = user._id;
            const { data } = await axios.post(
                `/chats/all-conversations/${id}`,
                {
                    jobId: selectedItem && selectedItem._id ? selectedItem._id : "",
                    search: "",
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        token: localStorage.getItem("token"),
                    },
                }
            );
            setConversationData(data);
        } catch (err) {
            console.error("Error fetching data:" + err);
        }
    };

    useEffect(() => {
        getConversationData();
    }, [user]);

    const handlePageChange = (value) => {
        setCurrentPage(value);
    };

    const [loadingChat, setLoadingChat] = useState(false);
    const [userEmails, setUserEmails] = useState([]);
    const [showEmail, setShowEmail] = useState({});
    const [showPhone, setShowPhone] = useState({});
    const [deductedFreelancers, setDeductedFreelancers] = useState([]);

    useEffect(() => {
        const fetchUserEmailsByEmployerId = async () => {
            try {
                const response = await axios.get(`/api/employer/getUserEmailsFromEmployer/${user._id}`);
                setUserEmails(response.data.userEmails);
            } catch (error) {
                console.error("Error fetching user emails by employerId:", error);
            }
        };
        if (user && user.role === "Employer") {
            fetchUserEmailsByEmployerId();
        }
    }, [user]);

    const handleShowAll = async (id) => {
        // Validate profile completion
        if (user) {
            if (!user.city) {
                sessionStorage.setItem("prevPath", "/freelancer-details"); // Store the intended path
                navigate("/myProfile");
                return;
            }
            if (!user.organisationName && !user.organisationDescription) {
                sessionStorage.setItem("prevPath", "/freelancer-details"); // Store the intended path
                navigate("/myProfile/company");
                return;
            }
        }

        const index = teamData?.findIndex((item) => item._id === id);
        const freelancer = teamData[index];

        // Check if freelancer has applied to any of employer's jobs
        const hasAppliedToEmployerJobs = freelancer?.appliedJobs?.some((job) => job.employer === user._id);

        // Check if freelancer email is already saved for this employer
        const isEmailAlreadySaved = userEmails?.includes(freelancer.email);
        logEvent("Button", `Clicked show email and phone number button`, user ? user.role : "Guest");

        // Only deduct connects if not previously deducted and not applied to jobs
        if (!hasAppliedToEmployerJobs && !isEmailAlreadySaved && !deductedFreelancers.includes(id)) {
            try {
                // Deduct connects
                const response = await axios.post("/api/user/deductConnectCreditoffreelancer", {
                    freelancerId: id,
                });

                if (response.data.message === "Please Buy Connects.") {
                    setShowModal(true);
                    return;
                }

                // Add freelancer to deducted list to prevent multiple deductions
                setDeductedFreelancers((prev) => [...prev, id]);
            } catch (error) {
                console.error("Error deducting connects:", error);
                return;
            }
        }

        // Update visibility states
        setShowEmail((prev) => ({ ...prev, [id]: true }));
        setShowPhone((prev) => ({ ...prev, [id]: true }));

        // Save email for the employer
        try {
            await axios.post("/api/employer/saveUserEmailInEmployer", {
                employerId: user._id,
                userEmail: freelancer.email,
            });
        } catch (error) {
            console.error("Error saving email:", error);
        }
    };

    const handleChatClick = async (id) => {
        // Validate profile completion
        if (!user.city) {
            sessionStorage.setItem("prevPath", "/freelancer-details");
            navigate("/myProfile");
            return;
        }
        if (user && !user.organisationName && !user.organisationDescription) {
            sessionStorage.setItem("prevPath", "/freelancer-details");
            navigate("/myProfile/company");
            return;
        }

        const index = teamData?.findIndex((item) => item._id === id);
        const freelancer = teamData[index];

        // Check if freelancer has applied to any of employer's jobs
        const hasAppliedToEmployerJobs = freelancer?.appliedJobs?.some((job) => job.employer === user._id);

        // Check if freelancer email is already saved for this employer
        const isEmailAlreadySaved = userEmails?.includes(freelancer.email);

        // Only deduct connects if not previously deducted and not applied to jobs
        if (!hasAppliedToEmployerJobs && !isEmailAlreadySaved && !deductedFreelancers.includes(id)) {
            try {
                // Deduct connects
                const response = await axios.post("/api/user/deductConnectCreditoffreelancer", {
                    freelancerId: id,
                });

                if (response.data.message === "Please Buy Connects.") {
                    setShowModal(true);
                    return;
                }

                // Add freelancer to deducted list to prevent multiple deductions
                setDeductedFreelancers((prev) => [...prev, id]);
            } catch (error) {
                console.error("Error deducting connects:", error);
                return;
            }
        }
        // Save email for the employer
        try {
            await axios.post("/api/employer/saveUserEmailInEmployer", {
                employerId: user._id,
                userEmail: freelancer.email,
            });
        } catch (error) {
            console.error("Error saving email:", error);
        }

        // Proceed with chat
        await ChatToUser(freelancer);
    };

    // HANDLE USERS CHAT:
    const ChatToUser = async (data) => {
        setLoadingChat(true);
        logEvent("Button", `Clicked chat button`, user ? user.role : "Guest");

        try {
            const response = await axios.post("/api/user/createRoom", {
                userId: data._id,
                employerId: user && user._id,
                jobId: "6613d85f0ef12e507f453a46",
            });
            if (response.data.success) {
                navigate("/message", { state: response.data.data });
                setLoadingChat(false);
            }
        } catch (error) {
            console.error("Error occurred while sending request:", error);
            setLoadingChat(false);
        }
    };
    const handleAddEvent = (str) => {
        logEvent("Button", `Clicked ${str} button`, user ? user.role : "Guest");
    };

    const [activeTooltip, setActiveTooltip] = useState(null);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    // Add useEffect for mobile detection
    React.useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // Handle tooltip interactions based on device
    const handleTooltipInteraction = (id) => {
        if (isMobile) {
            setTooltipOpen((prev) => ({ ...prev, [id]: !prev[id] }));
        }
    };

    // Handle mouse enter for desktop
    const handleMouseEnter = (id) => {
        if (!isMobile) {
            setTooltipOpen((prev) => ({ ...prev, [id]: true }));
        }
    };

    // Handle mouse leave for desktop
    const handleMouseLeave = (id) => {
        if (!isMobile) {
            setTooltipOpen((prev) => ({ ...prev, [id]: false }));
        }
    };
    const TooltipContent = React.memo(({ children }) => (
        <Typography
            sx={{
                fontSize: "14px", // Increased from 6px to 14px
                padding: "4px 8px",
                whiteSpace: "normal",
                maxWidth: "300px", // Added to ensure text wraps nicely
            }}
        >
            {children}
        </Typography>
    ));

    return (
        <>
            <div className="py-4" style={{ backgroundColor: "rgba(224, 220, 243, 0.212)" }}>
                <Grid item container md={12} xs={12} lg={12} justifyContent="center" alignItems="center" mt={5} mb={5}>
                    <h2
                        className="borderremove1"
                        style={{
                            backgroundColor: "white",
                            borderRadius: "10px",
                            paddingLeft: "10%",
                            paddingRight: "10%",
                            paddingTop: "1%",
                            paddingBottom: "1%",
                        }}
                    >
                        Freelancers Details
                    </h2>
                </Grid>

                <div className="borderremovePc" style={{ backgroundColor: "white", borderRadius: "10px", height: "auto", marginLeft: "auto", marginRight: "auto", marginBottom: "2.6%", borderRadius: "10px", paddingLeft: "30px", maxWidth: "1200px", width: "90%" }}>
                    <Grid item container direction="row" spacing={2}>
                        <Grid item container xs={12} md={12} justifyContent="center" mt={2} style={{ paddingTop: "10px" }}>
                            {/* <h1>Filters</h1>*/}
                            <img src={filterLogo} alt="Account" style={{ width: "25px", height: "25px" }} /> <h2 style={{ marginTop: "2px" }}>Filters</h2>
                        </Grid>
                        <Grid item container xs={6} md={3} justifyContent="center">
                            <Grid item container xs={12} md={12} justifyContent="start">
                                <Typography variant="subtitle1" gutterBottom sx={{ fontSize: "16px", color: "#000" }}>
                                    Expertise In
                                </Typography>
                            </Grid>
                            <Grid item container xs={12} md={12}>
                                <Select
                                    options={filteredProfiles?.map((data) => ({
                                        value: data,
                                        label: data,
                                    }))}
                                    placeholder={<div style={{ fontSize: "15px" }}>Search profile</div>}
                                    onChange={(selectedOption) => {
                                        setSearch({ ...search, profile: selectedOption?.value });
                                    }}
                                    value={search.profile ? { value: search.profile, label: search.profile } : null}
                                    onInputChange={(inputValue) => {
                                        setInputValue(inputValue);
                                        setIsTyping1(inputValue.trim() !== "");
                                        if (inputValue.trim() !== "") {
                                            setFilteredProfiles(filterOptions(inputValue));
                                        } else {
                                            setFilteredProfiles([]);
                                        }
                                    }}
                                    inputValue={inputValue}
                                    menuIsOpen={isTyping1}
                                    styles={customStyles}
                                />
                            </Grid>
                        </Grid>

                        <Grid item container xs={6} md={3} justifyContent="center">
                            <Grid item container xs={12} md={12} justifyContent="start">
                                <Typography variant="subtitle1" gutterBottom sx={{ fontSize: "16px", color: "#000" }}>
                                    Availability
                                </Typography>
                            </Grid>
                            <Grid item container xs={12} md={12}>
                                {/* <Form.Control onChange={handleChange} name="degree" value={search.degree} placeholder="Search degree" style={{ borderRadius: '5px', border: '1px solid #dddddd', textAlign: 'start', fontSize: "15px", marginTop:"0px", height: "45px" }} /> */}
                                <Select
                                    styles={customStyles}
                                    options={AVAILABILITY?.map((data) => ({
                                        value: data,
                                        label: data,
                                    }))}
                                    placeholder={<div style={{ fontSize: "15px" }}>Search availability</div>}
                                    onChange={(selectedOption) => {
                                        setSearch({
                                            ...search,
                                            availability: selectedOption?.value,
                                        });
                                    }}
                                    value={
                                        search.availability
                                            ? {
                                                  value: search.availability,
                                                  label: search.availability,
                                              }
                                            : null
                                    }
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={6} md={3} container justifyContent="center">
                            <Grid item container xs={12} md={12} justifyContent="start">
                                <Typography variant="subtitle1" gutterBottom sx={{ fontSize: "16px", color: "#000" }}>
                                    Skills
                                </Typography>
                            </Grid>
                            <Grid item container xs={12} md={12}>
                                <Select
                                    styles={customStyles}
                                    options={pdskills?.map((data) => ({
                                        value: data,
                                        label: data,
                                    }))}
                                    placeholder={<div style={{ fontSize: "15px" }}>Search skills</div>}
                                    onChange={(selectedOptions) => {
                                        setSearch({
                                            ...search,
                                            skills: selectedOptions.map((option) => option.value),
                                        });
                                    }}
                                    value={
                                        search.skills
                                            ? search.skills.map((skill) => ({
                                                  value: skill,
                                                  label: skill,
                                              }))
                                            : null
                                    }
                                    isMulti
                                    onInputChange={handleInputChange2}
                                    menuIsOpen={isTyping}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={6} md={3} container justifyContent="center">
                            <Grid item xs={12} md={12} container justifyContent="start">
                                <Typography variant="subtitle1" gutterBottom sx={{ fontSize: "16px", color: "#000", paddingLeft: "25px" }}>
                                    Profile
                                </Typography>
                            </Grid>
                            <div className="row my-2 profilemobilerespon">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-check">
                                        <input className="form-check-input mt-2" type="checkbox" id="Experience" name="Experience" onChange={handleFilter} checked={search.jobType.includes("Experience")} />
                                        <label className="form-check-label " style={{ marginLeft: "-5px" }} htmlFor="Experience">
                                            Experience
                                        </label>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-check">
                                        <input className="form-check-input mt-2" type="checkbox" id="Fresher" name="Fresher" onChange={handleFilter} checked={search.jobType.includes("Fresher")} />
                                        <label className="form-check-label " style={{ marginLeft: "-5px" }} htmlFor="Fresher">
                                            Fresher
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item container xs={12} md={12} justifyContent="end" sx={{ paddingRight: "3%", paddingBottom: "2%", paddingTop: "1%" }}>
                            <Button
                                onClick={() => {
                                    clearSearch();
                                    handleAddEvent("Clear filter");
                                }}
                                variant="contained"
                                sx={{
                                    textTransform: "none",
                                    fontSize: "16px",
                                    textDecoration: "none",
                                    background: "none",
                                    color: "#4B92C8",
                                    "&:hover": { background: "#4B92C8", color: "white" },
                                }}
                            >
                                Clear
                            </Button>
                            <Button
                                onClick={() => {
                                    handleSearch();
                                    handleAddEvent("Apply filter");
                                }}
                                variant="contained"
                                sx={{
                                    textTransform: "none",
                                    fontSize: "16px",
                                    marginLeft: "20px",
                                    textDecoration: "none",
                                    background: "#4B92C8",
                                    "&:hover": { background: "none", color: "#4B92C8" },
                                }}
                            >
                                Apply
                            </Button>
                        </Grid>
                    </Grid>
                </div>

                {/*MOBILE FILTER BOX*/}

                <div className="borderremoveMob" style={{ backgroundColor: "white", borderRadius: "10px", height: "auto", marginLeft: "auto", marginRight: "auto", marginBottom: "5%", borderRadius: "10px", paddingLeft: "30px", maxWidth: "1200px", width: "90%" }}>
                    <Grid item container direction="row" spacing={2}>
                        <Grid item container xs={12} md={12} justifyContent="center" mt={2}>
                            {/* <h1>Filters</h1>*/}
                            <img src={filterLogo} alt="Account" style={{ width: "25px", height: "25px" }} /> <h2 style={{ marginTop: "2px" }}>Filters</h2>
                        </Grid>
                        <div className="row my-4 px-4" style={{ justifyContent: "center" }}>
                            <div className="col-3 my-4" style={{ minWidth: "270px" }}>
                                <Grid item container xs={12} md={12} justifyContent="start">
                                    <Typography variant="subtitle1" gutterBottom sx={{ fontSize: "16px", color: "#000" }}>
                                        Expertise In
                                    </Typography>
                                </Grid>
                                <Grid item container xs={12} md={12}>
                                    {/* <Form.Control onChange={handleChange} name="profile" value={search.profile} placeholder="Search profile" style={{ borderRadius: '5px', border: '1px solid #dddddd', textAlign: 'start', fontSize: "15px", marginTop:"0px", height: "45px" }} />*/}
                                    <Select
                                        styles={customStylesOne}
                                        options={profiles?.map((data) => ({
                                            value: data,
                                            label: data,
                                        }))}
                                        placeholder={<div style={{ fontSize: "15px" }}>Search profile</div>}
                                        onChange={(selectedOption) => {
                                            setSearch({ ...search, profile: selectedOption?.value });
                                        }}
                                        value={search.profile ? { value: search.profile, label: search.profile } : null}
                                    />
                                </Grid>
                            </div>

                            <div className="col-3 my-4" style={{ minWidth: "270px" }}>
                                <Grid item container xs={12} md={12} justifyContent="start">
                                    <Typography variant="subtitle1" gutterBottom sx={{ fontSize: "16px", color: "#000" }}>
                                        Availability
                                    </Typography>
                                </Grid>
                                <Grid item container xs={12} md={12}>
                                    {/* <Form.Control onChange={handleChange} name="degree" value={search.degree} placeholder="Search degree" style={{ borderRadius: '5px', border: '1px solid #dddddd', textAlign: 'start', fontSize: "15px", marginTop:"0px", height: "45px" }} /> */}
                                    <Select
                                        styles={customStylesOne}
                                        options={AVAILABILITY?.map((data) => ({
                                            value: data,
                                            label: data,
                                        }))}
                                        placeholder={<div style={{ fontSize: "15px" }}>Search availability</div>}
                                        onChange={(selectedOption) => {
                                            setSearch({
                                                ...search,
                                                availability: selectedOption?.value,
                                            });
                                        }}
                                        value={
                                            search.availability
                                                ? {
                                                      value: search.availability,
                                                      label: search.availability,
                                                  }
                                                : null
                                        }
                                    />
                                </Grid>
                            </div>

                            <div className="col-3 my-4" style={{ minWidth: "270px" }}>
                                <Grid item container xs={12} md={12} justifyContent="start">
                                    <Typography variant="subtitle1" gutterBottom sx={{ fontSize: "16px", color: "#000" }}>
                                        Skills
                                    </Typography>
                                </Grid>
                                <Grid item container xs={12} md={12}>
                                    <Select
                                        styles={customStylesOne}
                                        options={pdskills?.map((data) => ({
                                            value: data,
                                            label: data,
                                        }))}
                                        placeholder={<div style={{ fontSize: "15px" }}>Search skills</div>}
                                        onChange={(selectedOptions) => {
                                            setSearch({
                                                ...search,
                                                skills: selectedOptions.map((option) => option.value),
                                            });
                                        }}
                                        value={
                                            search.skills
                                                ? search.skills.map((skill) => ({
                                                      value: skill,
                                                      label: skill,
                                                  }))
                                                : null
                                        }
                                        isMulti
                                        onInputChange={handleInputChange2}
                                        menuIsOpen={isTyping}
                                    />
                                </Grid>
                            </div>
                            <div className="col-3  pl-5 my-4" style={{ minWidth: "270px" }}>
                                <Grid item xs={12} md={12} container justifyContent="start">
                                    <Typography variant="subtitle1" gutterBottom sx={{ fontSize: "16px", color: "#000" }}>
                                        Profile
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} container justifyContent="start">
                                    <div className="row my-2">
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-check">
                                                <input className="form-check-input mt-2" type="checkbox" id="Experience" name="Experience" onChange={handleFilter} checked={search.jobType.includes("Experience")} />
                                                <label className="form-check-label " style={{ marginLeft: "-5px" }} htmlFor="Experience">
                                                    Experience
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-check">
                                                <input className="form-check-input mt-2" type="checkbox" id="Fresher" name="Fresher" onChange={handleFilter} checked={search.jobType.includes("Fresher")} />
                                                <label className="form-check-label " style={{ marginLeft: "-5px" }} htmlFor="Fresher">
                                                    Fresher
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </div>
                        </div>
                        <Grid
                            item
                            container
                            xs={12}
                            md={12}
                            justifyContent="end"
                            sx={{
                                paddingRight: "3%",
                                paddingBottom: "2%",
                                paddingTop: "1%",
                                marginBottom: "10px",
                            }}
                        >
                            <Button
                                onClick={() => {
                                    clearSearch();
                                    handleAddEvent("Clear filter");
                                }}
                                variant="contained"
                                sx={{
                                    textTransform: "none",
                                    fontSize: "16px",
                                    textDecoration: "none",
                                    background: "none",
                                    color: "#4B92C8",
                                    "&:hover": { background: "#4B92C8", color: "white" },
                                }}
                            >
                                Clear
                            </Button>
                            <Button
                                onClick={() => {
                                    handleSearch();
                                    handleAddEvent("Apply filter");
                                }}
                                variant="contained"
                                sx={{
                                    textTransform: "none",
                                    fontSize: "16px",
                                    marginLeft: "20px",
                                    textDecoration: "none",
                                    background: "#4B92C8",
                                    "&:hover": { background: "none", color: "#4B92C8" },
                                }}
                            >
                                Apply
                            </Button>
                        </Grid>
                    </Grid>
                </div>
                <div
                    className="borderremove"
                    style={{
                        backgroundColor: "white",
                        borderRadius: "10px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: "50px",
                        maxWidth: "1200px",
                        width: "90%",
                        overflow: "auto",
                    }}
                >
                    <div style={{ width: "100%", minWidth: "1200px" }}>
                        <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ borderRadius: "10px 10px  0  0 ", fontSize: { md: "16px", sm: "14px", xs: "12px" }, fontWeight: "500", color: "#FFF", paddingTop: "1%", background: "#4B92C8", paddingLeft: "3%" }}>
                            <Grid item xs={1.8} md={1.8} container justifyContent={{ xs: "start", md: "start" }} alignItems="start" paddingLeft={{ xs: "30px", md: "45px" }}>
                                <p>Name</p>
                            </Grid>
                            <Grid item xs={1} md={1.2} container justifyContent="start" alignItems="start">
                                <p>Rate</p>
                            </Grid>
                            <Grid item xs={2} md={1.7} container justifyContent="start" alignItems="start">
                                <p>Profile</p>
                            </Grid>
                            <Grid item xs={1.5} md={1.5} container justifyContent="start" alignItems="start">
                                <p>Availability</p>
                            </Grid>
                            <Grid item xs={1.6} md={1.8} container justifyContent="start" alignItems="start">
                                <p>Skills</p>
                            </Grid>
                            <Grid item xs={2} md={1.7} container justifyContent="start" alignItems="start">
                                <p>Email</p>
                            </Grid>
                            <Grid item xs={2} md={2.3} container justifyContent="start" alignItems="start" paddingLeft={{ xs: "20px", md: "20px" }}>
                                <p>Contact Number</p>
                            </Grid>
                        </Grid>

                        {/* Data rows */}
                        {loading ? (
                            <>
                                <Grid item xs={12} md={12} container justifyContent="start" alignItems="center" style={{ paddingLeft: "5%" }}>
                                    <div className=" mx-auto" style={{ marginBottom: "20px", marginTop: "20px" }}>
                                        <ScaleLoader color={"#4B92C8"} loading={true} size={15} aria-label="Loading Spinner" data-testid="loader" />
                                    </div>
                                </Grid>
                            </>
                        ) : (
                            <>
                                <div style={{ marginTop: "10px" }}></div>
                                {teamData && teamData.length > 0 ? (
                                    <>
                                        {(user?.role === "Employer"
                                            ? teamData?.filter((item) => item?.connectCredit > 0 || (Array.isArray(conversationData) && conversationData.some((conversation) => conversation?.participants?.some((participant) => participant?._id === item?._id) && conversation?.participants?.some((participant) => participant?.role === "Employer")))).sort((a, b) => (b.connectSpent || 0) - (a.connectSpent || 0)) // Sort by connectSpent in descending order
                                            : teamData.sort((a, b) => (b.connectSpent || 0) - (a.connectSpent || 0))
                                        ) // Sort non-employer view as well
                                            .map((item, index) => (
                                                <div key={index}>
                                                    <Grid container justifyContent="center" alignItems="center" direction="row" sx={{ fontSize: { sm: "13px", xs: "12px" }, fontWeight: "400", color: "#000", paddingLeft: "3%" }}>
                                                        <Grid item xs={1.8} md={1.8} container justifyContent="start" alignItems="center" style={{ overflowWrap: "anywhere", wordBreak: "break-all" }}>
                                                            <div style={{ textAlign: "center", color: ` #666`, height: "47px", width: "47px", fontSize: "12px", border: !item?.resume?.PersonalDetails?.photo?.url && "2px solid gray", borderRadius: "50%", paddingTop: "0px", marginTop: "-2px", marginRight: "5px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                {item?.resume?.PersonalDetails?.photo?.url ? (
                                                                    <a onClick={handleAddEvent("user profile")} href={`/previewFreelancer/${item?.name}/${item._id}`} target="_blank" style={{ width: "50px", height: "50px" }}>
                                                                        <img
                                                                            src={item?.resume?.PersonalDetails?.photo?.url}
                                                                            alt="Profile"
                                                                            style={{
                                                                                width: "100%",
                                                                                height: "100%",
                                                                                objectFit: "cover",
                                                                                borderRadius: "50%",
                                                                                boxShadow: "0 0 5px rgba(0,0,0,0.2)",
                                                                                border: "2px solid white",
                                                                            }}
                                                                        />
                                                                    </a>
                                                                ) : (
                                                                    <a onClick={handleAddEvent("user profile")} href={`/previewFreelancer/${item?.name}/${item._id}`} target="_blank" style={{ width: "50px", height: "50px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                        <p
                                                                            style={{
                                                                                fontSize: "18px",
                                                                                marginBottom: "0px",
                                                                            }}
                                                                        >
                                                                            {item.name.slice(0, 1)}
                                                                        </p>
                                                                    </a>
                                                                )}
                                                            </div>
                                                            <a href={`/previewFreelancer/${item?.name}/${item._id}`} target="_blank">
                                                                <Tooltip title={<Typography sx={{ fontSize: "16px" }}>{item.name}</Typography>} arrow disableHoverListener={item.name.length <= 16}>
                                                                    <p
                                                                        style={{
                                                                            marginBottom: "0px",
                                                                            cursor: "pointer",
                                                                        }}
                                                                    >
                                                                        {item.name.length > 16 ? item.name.slice(0, 13) + "..." : item.name}
                                                                    </p>
                                                                </Tooltip>
                                                            </a>
                                                        </Grid>

                                                        <Grid item xs={1} md={1.2} container justifyContent="start" alignItems="start" style={{ overflowWrap: "anywhere", wordBreak: "break-all", paddingRight: "10px" }}>
                                                            <p style={{ marginBottom: "0px" }}>${item?.resume?.PersonalDetails?.minimumRate ? item?.resume?.PersonalDetails?.minimumRate : "N/A"} per hour</p>
                                                        </Grid>

                                                        <Grid item xs={2} md={1.7} container justifyContent="start" alignItems="start" style={{ overflowWrap: "anywhere", wordBreak: "break-all", paddingRight: "20px" }}>
                                                            <Tooltip
                                                                open={activeTooltip === `profile_${item._id}`}
                                                                onClose={() => setActiveTooltip(null)}
                                                                title={<TooltipContent>{item.resume.PersonalDetails ? item.resume.PersonalDetails.profile : "Not available"}</TooltipContent>}
                                                                arrow
                                                                PopperProps={{
                                                                    modifiers: [
                                                                        {
                                                                            name: "offset",
                                                                            options: {
                                                                                offset: [0, -10],
                                                                            },
                                                                        },
                                                                    ],
                                                                }}
                                                            >
                                                                <p
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        setActiveTooltip(activeTooltip === `profile_${item._id}` ? null : `profile_${item._id}`);
                                                                    }}
                                                                    style={{ marginBottom: "0px", cursor: "pointer" }}
                                                                >
                                                                    {item.resume.PersonalDetails ? truncateWithTooltip(item.resume.PersonalDetails.profile, 20) : "Not available"}
                                                                </p>
                                                            </Tooltip>
                                                        </Grid>

                                                        <Grid item xs={1.5} md={1.5} container justifyContent="start" alignItems="start" style={{ overflowWrap: "anywhere", wordBreak: "break-all", paddingRight: "20px" }}>
                                                            {/* <p style={{ marginBottom: "0px" }}>{item.availability ? item.availability : ""}</p> */}
                                                            <Tooltip
                                                                open={activeTooltip === `availability_${item._id}`}
                                                                onClose={() => setActiveTooltip(null)}
                                                                title={<TooltipContent>{item.availability || ""}</TooltipContent>}
                                                                arrow
                                                                PopperProps={{
                                                                    modifiers: [
                                                                        {
                                                                            name: "offset",
                                                                            options: {
                                                                                offset: [0, -10],
                                                                            },
                                                                        },
                                                                    ],
                                                                }}
                                                            >
                                                                <p
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        setActiveTooltip(activeTooltip === `availability_${item._id}` ? null : `availability_${item._id}`);
                                                                    }}
                                                                    style={{ marginBottom: "0px", cursor: "pointer" }}
                                                                >
                                                                    {item.availability ? truncateWithTooltip(item.availability, 20) : ""}
                                                                </p>
                                                            </Tooltip>
                                                        </Grid>

                                                        <Grid item xs={1.6} md={1.8} container justifyContent="start" alignItems="start" style={{ overflowWrap: "anywhere", wordBreak: "break-all", paddingRight: "20px", cursor: "pointer" }}>
                                                            <Tooltip
                                                                open={activeTooltip === `skills_${item._id}`}
                                                                onClose={() => setActiveTooltip(null)}
                                                                title={<TooltipContent>{item.resume.Skills && item.resume.Skills.length > 0 ? item.resume.Skills.map((data) => data.name).join(", ") : "Not available"}</TooltipContent>}
                                                                arrow
                                                                PopperProps={{
                                                                    modifiers: [
                                                                        {
                                                                            name: "offset",
                                                                            options: {
                                                                                offset: [0, -10],
                                                                            },
                                                                        },
                                                                    ],
                                                                }}
                                                            >
                                                                <p
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        setActiveTooltip(activeTooltip === `skills_${item._id}` ? null : `skills_${item._id}`);
                                                                    }}
                                                                    style={{ marginBottom: "0px", cursor: "pointer" }}
                                                                >
                                                                    {item.resume.Skills && item.resume.Skills.length > 0 ? truncateWithTooltip(item.resume.Skills.map((data) => data.name).join(", "), 20) : "Not available"}
                                                                </p>
                                                            </Tooltip>
                                                        </Grid>

                                                        <Grid item xs={2} md={1.7} container justifyContent="start" alignItems="start" style={{ overflowWrap: "anywhere", wordBreak: "break-all", paddingRight: "20px" }}>
                                                            {user && user.role === "Employer" ? (
                                                                userEmails && userEmails.length > 0 && userEmails.includes(item.email) ? (
                                                                    <ClickAwayListener onClickAway={() => setTooltipOpen((prev) => ({ ...prev, [item._id]: false }))}>
                                                                        <div onClick={() => handleTooltipInteraction(item._id)} onMouseEnter={() => handleMouseEnter(item._id)} onMouseLeave={() => handleMouseLeave(item._id)}>
                                                                            <Tooltip open={tooltipOpen[item._id] || false} title={<Typography sx={{ fontSize: "16px" }}>{item.email}</Typography>} arrow>
                                                                                <p style={{ marginBottom: "0px", cursor: "pointer" }}>{item.email ? <>{item.email.length > 20 ? item.email.slice(0, 15) + "..." : item.email}</> : <>Not available</>}</p>
                                                                            </Tooltip>
                                                                        </div>
                                                                    </ClickAwayListener>
                                                                ) : (
                                                                    <span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                        {showEmail[item._id] ? (
                                                                            <ClickAwayListener onClickAway={() => setTooltipOpen((prev) => ({ ...prev, [item._id]: false }))}>
                                                                                <div onClick={() => handleTooltipInteraction(item._id)} onMouseEnter={() => handleMouseEnter(item._id)} onMouseLeave={() => handleMouseLeave(item._id)}>
                                                                                    <Tooltip open={tooltipOpen[item._id] || false} title={<Typography sx={{ fontSize: "16px" }}>{item.email}</Typography>} arrow>
                                                                                        <p style={{ marginBottom: "0px", cursor: "pointer" }}>{item.email ? <>{item.email.length > 20 ? item.email.slice(0, 15) + "..." : item.email}</> : <>Not available</>}</p>
                                                                                    </Tooltip>
                                                                                </div>
                                                                            </ClickAwayListener>
                                                                        ) : (
                                                                            <>
                                                                                <p style={{ margin: "0px" }}>***************</p>
                                                                                {!showEmail[item._id] && (
                                                                                    <button className="show-connect-button-employer" onClick={() => handleShowAll(item?._id)}>
                                                                                        Show
                                                                                    </button>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </span>
                                                                )
                                                            ) : (
                                                                <p>{item.email && item.email.includes("@") ? item.email[0] + item.email[1] + "*****" + item.email.slice(item.email.indexOf("@")) : "Not available"}</p>
                                                            )}
                                                        </Grid>

                                                        <Grid item xs={2} md={2.3} container justifyContent="start" alignItems="start" style={{ overflowWrap: "anywhere", wordBreak: "break-all", paddingLeft: "20px" }}>
                                                            {user && user.role === "Employer" ? (
                                                                userEmails && userEmails.length > 0 && userEmails.includes(item.email) ? (
                                                                    <p>
                                                                        +{item.resume?.PersonalDetails?.countryCode} {item.phone}
                                                                    </p>
                                                                ) : (
                                                                    <span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                        {showPhone[item._id] ? <p style={{ margin: "0px" }}> {item.phone}</p> : <p style={{ margin: "0px" }}>**********</p>}
                                                                        {!showPhone[item._id] && (
                                                                            <button className="show-connect-button-employer" onClick={() => handleShowAll(item?._id)}>
                                                                                Show
                                                                            </button>
                                                                        )}
                                                                    </span>
                                                                )
                                                            ) : (
                                                                <p>{item.phone ? "*****" + item.phone.slice(6, 9) + "**" : "Not available"}</p>
                                                            )}

                                                            {user && user.role === "Employer" ? (
                                                                loadingChat ? (
                                                                    <IoChatboxOutline
                                                                        onClick={() => handleChatClick(item?._id)}
                                                                        className="chatIconOfemp ml-auto formobilescreenchaticon"
                                                                        style={{
                                                                            fontSize: "22px",
                                                                            color: "rgb(6, 61, 180)",
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <IoChatboxOutline
                                                                        onClick={() => handleChatClick(item?._id)}
                                                                        className="chatIconOfemp ml-auto formobilescreenchaticon"
                                                                        style={{
                                                                            fontSize: "22px",
                                                                            cursor: "pointer",
                                                                        }}
                                                                    />
                                                                )
                                                            ) : null}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} md={12}>
                                                        <hr
                                                            style={{
                                                                border: "1px solid #dadada",
                                                                width: "100%",
                                                                height: "1px",
                                                            }}
                                                        />
                                                    </Grid>
                                                </div>
                                            ))}
                                    </>
                                ) : (
                                    <p style={{ textAlign: "center" }}>No eligible freelancers found.</p>
                                )}

                                {/* Pagination component */}
                                <Grid item xs={12} md={12} container justifyContent="end" alignItems="end" sx={{ marginBottom: "1%", paddingRight: "5%" }}>
                                    {totalpages > 1 ? (
                                        <>
                                            {/* 
          <MuiPagination
            count={totalpages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            size="large"
            //   shape="rounded"
            variant="outlined"
            className="mb-4"
          />
          */}
                                        </>
                                    ) : (
                                        <>
                                            <div style={{ marginTop: "30px" }}></div>
                                        </>
                                    )}
                                </Grid>
                            </>
                        )}
                    </div>
                </div>
                {totalpages > 1 ? (
                    <>
                        <div style={{ textAlign: "center", maxWidth: "1200px", marginInline: "auto" }}>
                            <PaginationWithTooltip totalPages={totalpages} currentPage={currentPage} onPageChange={setCurrentPage} />
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </div>

            <ToastContainer position="top-center" autoClose={10000} />
        </>
    );
}

export default CandidateDetail;
