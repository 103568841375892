import React, { useState, useEffect } from "react";
import axios from "axios";
import "./AppliedJobReview.scss";
import vector1 from "../../assets/images/Vector.png";
import { Navigate, useLocation } from "react-router-dom";
import { RxDotFilled, RxPencil1 } from "react-icons/rx";
import { MONTH_DETAILS } from "../data";
import { HiOutlinePencil } from "react-icons/hi";
import MonthCalculator from "./MonthCalculator";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import SimpleDialogNew from "../EmployerNew/Employertemplates/ChatDialogNew";
import { addEllipsis, timeAgo } from "../../utils/helper";
import SeeMoreLess from "./SeeMoreLess";
import { MdContentCopy } from "react-icons/md";
import CloseButton from "./CloseButton";
import PropagateLoader from "react-spinners/PropagateLoader";
import PreviewModel from "../Modal/PreviewModel";
import Pagination from "./Pagination";
import { Select } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import { FaFileDownload } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ViewPortfolioprojects from "../Modal/ViewPortfolioprojects";
import { IoArrowRedoCircleOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { logEvent } from "../../utils/Analytics";
const AppliedJobReview = () => {
    const role = localStorage.getItem("userType");
    const [state2, setState2] = useState(null); // Assuming state2 is your state variable
    const [openPopup, setOpenPopup] = useState(false);
    const { appliedJobId } = useParams();
    const [isExpanded, setIsExpanded] = useState(false);
    const [hoverText, setHoverText] = useState("Copy to clipboard!");
    const [linkCopied, setLinkCopied] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const [hoveredProject, setHoveredProject] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null);
    const [loading, setLoading] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [bid, setbid] = useState(0);
    const [bidchange, setbidchange] = useState(false);
    const userType = localStorage.getItem("userType");
    const { user } = useSelector((state) => state.user);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);


     const handleAddEvent = (str) => {
            logEvent("Button", `Clicked ${str} button`, user ? user.role : "Guest");
        }

    useEffect(() => {
        if (state2?.userBid) {
            setbid(state2.userBid);
        }
    }, [state2]);
    const handleUpdateStatus = async (status) => {
        logEvent("Button", `Clicked ${status} button`, user ? user.role : "Guest");
        
        var reqBody = {
            function: status,
            data: [state2._id],
        };

        console.log(reqBody);
        try {
            await axios.post(
                "/api/employer/changeStatusAppliedJob",
                { reqBody },
                {
                    headers: {
                        "Content-Type": "application/json",
                        token: localStorage.getItem("token"),
                    },
                }
            );

            toast.success("Status changed successfully");
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } catch (error) {
            toast.error("Something went wrong!!");
            console.log(error);
        }
    };

    const handleupdatebid = async () => {
        try {
            const res = await axios.put(
                `/api/user/updatebid/${state2._id}`,
                {
                    userBid: bid,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        token: localStorage.getItem("token"),
                    },
                }
            );
            if (res.error) {
                console.log(res.error);
            }
            if (res.status >= 200 && res.status < 300) {
                toast.success("Bid updated successfully");
                setbidchange(false);
            } else {
                throw new Error("Unexpected response status");
            }
        } catch (e) {
            toast.error("Something went wrong!!");
            console.log(e);
        }
    };

    const handleDownload2 = async (fileId, fileName) => {
        try {
            const response = await axios({
                url: `/api/user/getDownloadFile/${fileId}?fileName=${fileName}`, // Your backend endpoint
                method: "GET",
                responseType: "blob", // Important: this ensures the response is processed as a blob (binary data)
            });

            // Create a link element, set its URL to the blob, and trigger the download
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();

            // Cleanup
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error("Error downloading file:", error);
            alert("Failed to download the file.");
        }
    };

    const projectsPerPage = 3;
    const totalPages = Math.ceil(state2?.profilePortfolio?.length / projectsPerPage);

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(state2?.resume?.PersonalDetails?.videoIntroduction);
        setLinkCopied(true);
        setHoverText("Copied!");
        setShowTooltip(true);
        setTimeout(() => {
            setHoverText("Copy to clipboard!");
            setLinkCopied(false);
            setShowTooltip(false);
        }, 800);
    };

    const tooltipStyles = {
        position: "absolute",
        top: "100%",
        left: "80%",
        transform: "translateX(-50%)",
        marginBottom: "8px",
        padding: "5px 10px",
        background: "#555",
        color: "#fff",
        borderRadius: "4px",
        fontSize: "12px",
        whiteSpace: "nowrap",
        zIndex: 10,
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Optional: adds shadow to tooltip
    };
    const handleProjectClick = async (project) => {
        //console.log(project, "jfkdk");
        setLoading(true);
        setSelectedProject(null);
        await new Promise((resolve) => setTimeout(resolve, 500));
        setSelectedProject(project);
        setLoading(false);
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios.post("/api/user/reviewdetails", {
                    Id: appliedJobId,
                });
                setState2(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [appliedJobId]);

    const [emailData, setEmailData] = useState(null);

    useEffect(() => {
        const fetchEmailData = async () => {
            try {
                const response = await axios.get(`/api/employer/getUserEmailsFromEmployer/${user?._id}`);

                setEmailData(response?.data?.userEmails);
            } catch (e) {
                console.error(e);
            }
        };

        fetchEmailData();
    }, [state2]);

    console.log(state2, "state2");
    useEffect(() => {
        console.log(state2);
    }, [state2]);

    const openPreviewPageInNewTab = (state) => {
        const data = state;
        
        // Create sanitized versions of each component
        const sanitizedProfile = data.profile?.replace(/[^\w\s-]/g, "-").replace(/\s+/g, "-").replace(/-+/g, "-") || "";
        const sanitizedOpportunityType = data.opportunityType?.replace(/[^\w\s-]/g, "-").replace(/\s+/g, "-").replace(/-+/g, "-") || "";
        const sanitizedOrgName = data.organisationName?.replace(/[^\w\s-]/g, "-").replace(/\s+/g, "-").replace(/-+/g, "-") || "";
        
        // Construct URL with sanitized components
        const url = `/preview/${sanitizedProfile}-${sanitizedOpportunityType}-at-${sanitizedOrgName}_${data._id}`.toLowerCase();
        
        // Open URL in new tab
        window.open(url);
    };

    const calculateTotalExperience = (internships, jobs) => {
        let totalMonths = 0;

        // Function to calculate the difference in months between two dates
        function monthDiff(d1, d2) {
            let months;
            months = (d2.getFullYear() - d1.getFullYear()) * 12;
            months -= d1.getMonth();
            months += d2.getMonth();
            return months <= 0 ? 0 : months;
        }

        // Calculate total months for internships
        internships.forEach((internship) => {
            const startDate = new Date(internship.startDate);
            const endDate = new Date(internship.endDate);
            totalMonths += monthDiff(startDate, endDate);
        });

        // Calculate total months for jobs
        jobs.forEach((job) => {
            const startDate = new Date(job.startDate);
            const endDate = new Date(job.endDate);
            totalMonths += monthDiff(startDate, endDate);
        });

        // Calculate years and remaining months
        const years = Math.floor(totalMonths / 12);
        const months = totalMonths % 12;

        // Construct the result string
        let result = "(";
        if (years === 1) {
            result += years + " YEAR ";
        } else if (years > 1) {
            result += years + " YEARS ";
        }

        if (months === 1) {
            result += months + " MONTH";
        } else if (months > 1) {
            result += months + " MONTHS";
        }
        result += ")";

        return result;
    };

    const navigate = useNavigate();

    const calculateDaysDifference = (startDate) => {
        const start = new Date(startDate);
        const end = new Date();

        // Calculate the difference in milliseconds
        const differenceInMilliseconds = end - start;

        // Convert the difference to days
        const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

        // Set the state with the calculated difference
        return differenceInDays;
    };

    const handleChatClick = async (userId, employerId, appliedJobId, mainId, navigate) => {
        try {
            const response = await axios.post(
                "/api/employer/sendMessagebyclickingchaticon",
                {
                    sender: employerId,
                    receiver: [[mainId, userId]],
                    appliedJobId: appliedJobId,
                    // message: '' // You can customize the message content
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        token: localStorage.getItem("token"),
                    },
                }
            );

            console.log(response.data.data, "wff");
            if (response.status === 200) {
                const roomId = response.data.data;
                navigate("/message", { state: roomId });
            }
        } catch (error) {
            console.error("Error creating chat room:", error);
            // Handle error (e.g., show a notification to the user)
        }
    };

    const getYouTubeEmbedUrl = (url) => {
        try {
            const urlObj = new URL(url);
            let videoId;

            if (urlObj.hostname === "youtu.be") {
                // Shortened YouTube URL (e.g., https://youtu.be/K0pbfQM1huY)
                videoId = urlObj.pathname.substring(1);
            } else if (urlObj.pathname.includes("/shorts/")) {
                // YouTube Shorts URL (e.g., https://youtube.com/shorts/6WKzH7jVhgs)
                videoId = urlObj.pathname.split("/shorts/")[1];
            } else {
                // Standard YouTube URL (e.g., https://www.youtube.com/watch?v=K0pbfQM1huY)
                videoId = urlObj.searchParams.get("v");
            }

            return `https://www.youtube.com/embed/${videoId}`;
        } catch (e) {
            console.error("Invalid URL", e);
            return null;
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            console.log(window.innerWidth, "windowWidth");
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (
        <>
            <div className="modal fade" id="previewmodalresume" tabIndex="-1" aria-labelledby="previewModalresumeLabel" aria-hidden="true">
                <div className="modal-dialog custom-modal-dialog unique-modal-dialog modal-dialog-centered width-settingofmodal">
                    <div
                        className="modal-content px-3 py-3"
                        style={{
                            borderRadius: "15px",
                            // // marginTop: "10px",
                            height: "95vh",
                        }}
                    >
                        <div className="preview-header">
                            <h2 style={{ fontSize: "28px" }}>{selectedProject?.projectTitle}</h2>

                            <CloseButton className="btnclosepreview" />
                        </div>
                        <div
                            className="modal-body"
                            style={{
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                overflowY: "auto",
                                paddingLeft: "50px",
                                paddingRight: "50px",
                            }}
                        >
                            {loading ? (
                                <div className="" style={{ textAlign: "center", paddingBottom: "220px" }}>
                                    <p
                                        style={{
                                            color: "#4B92C8",
                                            fontWeight: "500",
                                            fontSize: "20px",
                                            marginLeft: "43px",
                                            marginBottom: "5px",
                                            marginTop: "220px",
                                        }}
                                    >
                                        Loading..
                                    </p>
                                    <PropagateLoader color={"#4B92C8"} loading={true} size={15} aria-label="Loading Spinner" data-testid="loader" style={{ marginLeft: "27px" }} />
                                </div>
                            ) : (
                                selectedProject && (
                                    <div className={`fade-in ${!loading ? "show" : ""}`}>
                                        <div className="mb-3 flex-container-portfolio">
                                            <div
                                                className="leftsectionpreviewport"
                                                style={{
                                                    flex: 1,
                                                    marginRight: "20px",
                                                    position: "sticky",
                                                    alignSelf: "flex-start",
                                                }}
                                            >
                                                
                                                {selectedProject.yourRole !== "" && (
                                                    <div style={{ marginBottom: "25px" }}>
                                                        <p style={{ fontSize: "16px" }}>
                                                            <b>My role:</b> {selectedProject.yourRole}
                                                        </p>
                                                    </div>
                                                )}
                                                {selectedProject.projectDescription !== "" && (
                                                    <div style={{ marginBottom: "25px" }}>
                                                        <p style={{ fontSize: "16px" }}>
                                                            <b>Project Description:</b>{" "}
                                                            <span
                                                                dangerouslySetInnerHTML={{
                                                                    __html: selectedProject.projectDescription.replace(/\n/g, "<br />"),
                                                                }}
                                                            />
                                                            {/* {selectedProject.projectDescription} */}
                                                        </p>
                                                    </div>
                                                )}
                                                <div style={{ marginBottom: "25px" }}>
                                                    <h4>Skills and deliverables</h4>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexWrap: "wrap",
                                                        }}
                                                    >
                                                        {selectedProject.skills.map((skill) => (
                                                            <span key={skill.value} className="post1234forresume mr-4 mt-3">
                                                                {skill.label}
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                                <hr
                                                    //className="dropdown-divider1 mb-4 mt-3"
                                                    style={{
                                                        height: "0.1px",
                                                        color: "rgb(185, 185, 185)",
                                                    }}
                                                />
                                            </div>
                                            <div
                                                className="mb-3 rightsectionpreviewport"
                                                style={{
                                                    flex: 2,
                                                    minHeight: "240px",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <PreviewModel portfolioDetails={selectedProject} />

                                                <hr
                                                    className="dropdown-divider1 mb-4 mt-3"
                                                    style={{
                                                        height: "0.05px",
                                                        color: "rgb(185, 185, 185)",
                                                    }}
                                                />
                                                <div className="mb-3" style={{ marginTop: "35px" }}>
                                                    {/* <p style={{ fontSize: "18px", marginBottom: '15px' }}>More by <span style={{ color: "#2092c7" }}>{state2.PersonalDetails.firstName} {state2.PersonalDetails.lastName}</span></p> */}
                                                    <p style={{ fontSize: "18px", marginBottom: "15px" }}>
                                                        More by{" "}
                                                        <a href={"#"} style={{ color: "#2092c7" }}>
                                                            {state2?.resume?.PersonalDetails.firstName} {state2?.resume?.PersonalDetails.lastName}
                                                        </a>
                                                    </p>
                                                    <div className="row">
                                                        {state2?.profilePortfolio
                                                            ?.filter((project) => project._id !== selectedProject._id) // Exclude selected project
                                                            .slice(0, 3) // Get next 3 projects
                                                            .map((project) => (
                                                                <div className="col-md-4" key={project._id} style={{ cursor: "pointer" }} onClick={() => handleProjectClick(project)}>
                                                                    <div className="card">
                                                                        <img className="card-img-top" style={{ width: "100%", height: "200px", objectFit: "cover" }} src={project.selectedThumbnail.url} alt={`Project ${project._id}`} />
                                                                        <div className="card-body">
                                                                            <p className="card-text" style={{ color: "#2092c7" }}>
                                                                                {project.projectTitle}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {state2 ? (
                <div className="pb-5 wholeProfileWrapper paddingWrapper" style={{ backgroundColor: "rgba(224, 220, 243, 0.212)" }}>
                    <div className="InvoiceDownload section31 my-4 " style={{ letterSpacing: "0.3px" }}>
                        <div className="">
                            {/* Personal details wrapper  */}
                            <div>
                                {state2 !== null && (
                                    <>
                                        {userType && userType === "User" && (
                                            <div style={{ paddingLeft: "10px" }}>
                                                <h1 style={{ display: "inline-block" }} className="profileHeading">
                                                    {" "}
                                                    {state2.job.profile}{" "}
                                                    <span className="icon mx-1">
                                                        <IoArrowRedoCircleOutline color="blue" style={{ cursor: "pointer", color: "blue", marginBottom: "4px" }} onClick={() => openPreviewPageInNewTab(state2.job)} />
                                                    </span>{" "}
                                                </h1>
                                            </div>
                                        )}
                                        <div className="personalDetailsWrapper">
                                            {userType !== "User" && (
                                                <div className="borderBox  leftWrapper layoutDesign" style={{ flex: "1", marginTop: "10px" }}>
                                                    <div style={{ display: "flex", flexDirection: "column", gap: "10px", justifyContent: "center", alignItems: "center" }}>
                                                        {state2?.resume?.PersonalDetails?.photo?.url !== "" && (
                                                            <div className="phototodisplayindesktop">
                                                                <img
                                                                    src={state2?.resume?.PersonalDetails?.photo?.url}
                                                                    alt="Profile"
                                                                    style={{
                                                                        boxShadow: "0 0 5px rgba(0,0,0,0.2)",
                                                                        width: "100%",
                                                                        height: "100%",
                                                                        objectFit: "cover",
                                                                        borderRadius: "100%",
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                        <div className="d-flex" style={{ alignItems: "center", marginTop: "5px" }}>
                                                            <p style={{ fontSize: "25px", fontWeight: "500", color: "#333333", marginBottom: "3px" }}>
                                                                {state2?.resume?.PersonalDetails?.firstName} {state2?.resume?.PersonalDetails?.lastName}
                                                            </p>
                                                        </div>
                                                        <p style={{ fontSize: "17px", color: "#333333", marginBottom: "3px", textAlign: "center" }}>{state2?.resume?.PersonalDetails?.professionalTitle}</p>
                                                        {state2?.resume?.PersonalDetails?.minimumRate && (
                                                            <>
                                                                <p className="personalHeading">Hourly Rate ($)</p>
                                                                <p className="personalContent" style={{ marginTop: "-8px", marginBottom: "3px" }}>
                                                                    ${state2?.resume?.PersonalDetails?.minimumRate} per hour
                                                                </p>
                                                            </>
                                                        )}

                                                        {(emailData?.includes(state2?.user?.email) || state2?.user?.appliedJobs?.some((job) => job.employer === user?._id)) && (
                                                            <>
                                                                <p className="personalHeading">Contact Number</p>
                                                                <p className="personalContent" style={{ marginTop: "-8px", marginBottom: "3px" }}>
                                                                    {state2?.resume?.PersonalDetails?.countryCode && "+" + state2?.resume?.PersonalDetails?.countryCode} {state2?.resume?.PersonalDetails?.phone}

                                                                </p>

                                                                <p className="personalHeading">Email ID</p>
                                                                <p className="personalContent" style={{ marginTop: "-8px", marginBottom: "3px", textAlign: "center" }}>
                                                                    {state2?.resume?.PersonalDetails?.email}
                                                                </p>
                                                            </>
                                                        )}
                                                        <p className="personalHeading">Location</p>
                                                        <p className="personalContent" style={{ marginTop: "-8px", marginBottom: "3px", textAlign: "center" }}>
                                                            {state2?.resume?.PersonalDetails?.address}
                                                        </p>
                                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                            <div className="" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap", gap: "10px" }}>
                                                                    {role && role !== "User" && state2?.status !== "Hired" && state2?.status !== "Shortlisted" && (
                                                                        <>
                                                                            <button className=" shortcut-newappliedjob" style={{ color: "#1177d6", marginLeft: "0px" }} onClick={() => handleUpdateStatus("Shortlisted")}>
                                                                                <>{state2?.status !== "Hired" && state2?.status !== "Shortlisted" && <>Shortlist</>}</>
                                                                            </button>
                                                                        </>
                                                                    )}
                                                                    {role && role !== "User" && state2?.status !== "Hired" && (
                                                                        <>
                                                                            <button className=" shortcut-newappliedjob" style={{ color: "#1177d6" }} onClick={() => handleUpdateStatus("Hired")}>
                                                                                <>{state2?.status !== "Hired" && <>Hire</>}</>
                                                                            </button>
                                                                        </>
                                                                    )}
                                                                    {role && role !== "User" ? (
                                                                        <button className="shortcut-newappliedjob" onClick={() => {setOpenPopup(!openPopup); handleAddEvent("Message")}} style={{ color: "#1177d6" }}>
                                                                            Message
                                                                        </button>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            <div className="rightWrapper" style={{ flex: "2", gap: "20px 0px", marginLeft: userType === "User" && "0px" }}>
                                                {/* {state2?.resume?.PersonalDetails?.videoIntroduction && ( */}
                                                    <div className={`video-introduction rightOne layoutDesign ${userType === "User" && "videoChangeHeight"}`} style={{ marginTop: "10px" }}>
                                                        <div className="d-flex">
                                                            <h2 className="video-introduction__title">Video Introduction</h2>{" "}
                                                            {(userType !== "User" || state2?.user?._id !== user?._id) && (

                                                                state2?.resume?.PersonalDetails?.videoIntroduction && <span
                                                                    className="video-introduction__copy-button"
                                                                    style={{ marginBottom: "10px", marginLeft: "5px" }}
                                                                    onClick={handleCopyToClipboard}
                                                                    onMouseEnter={() => {
                                                                        if (!linkCopied) setHoverText("Copy to clipboard!");
                                                                        setShowTooltip(true);
                                                                    }}
                                                                    onMouseLeave={() => setShowTooltip(false)}
                                                                >
                                                                    <MdContentCopy />
                                                                    {showTooltip && <div className="video-introduction__tooltip">{hoverText}</div>}
                                                                </span>
                                                            )}
                                                        </div>

                                                        {userType === "User" && state2?.user?._id === user?._id && (

                                                            state2?.resume?.PersonalDetails?.videoIntroduction && (<div className="video-introduction__link-container">
                                                                <a href={state2?.resume?.PersonalDetails?.videoIntroduction} target="_blank" rel="noopener noreferrer" className="video-introduction__link">
                                                                    Your Video Introduction
                                                                </a>
                                                                <span
                                                                    className="video-introduction__copy-button"
                                                                    onClick={handleCopyToClipboard}
                                                                    onMouseEnter={() => {
                                                                        if (!linkCopied) setHoverText("Copy to clipboard!");
                                                                        setShowTooltip(true);
                                                                    }}
                                                                    onMouseLeave={() => setShowTooltip(false)}
                                                                >
                                                                    <MdContentCopy />
                                                                    {showTooltip && <div className="video-introduction__tooltip">{hoverText}</div>}
                                                                </span>
                                                            </div>)
                                                        )}
                                                        {state2?.resume?.PersonalDetails?.videoIntroduction && <div className="video-introduction__wrapper">
                                                            <iframe src={getYouTubeEmbedUrl(state2?.resume?.PersonalDetails?.videoIntroduction)} title="Video Introduction" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen className="video-introduction__iframe" />
                                                        </div>}
                                                    </div>
                                                {/* )} */}

                                                {state2?.resume?.PersonalDetails?.profileCategories && (
                                                    <div className="d-flex align-items-start justify-content-start borderBox  flex-column layoutDesign rightTwo " style={{ width: "100%", marginTop: userType === "User" && "40px" }}>
                                                        <div className="d-flex">{state2?.resume?.PersonalDetails?.profileCategories && <p style={{ margin: "0px", fontWeight: "400", fontSize: "18px", color: "#666666" }}> Profile Category </p>}</div>

                                                        {state2?.resume?.PersonalDetails?.profileCategories.map((category, index) => (
                                                            <div key={category.id}>
                                                                <p style={{ fontSize: "17px", color: "#333333", fontWeight: "400" }}>{category?.profile}</p>
                                                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                                                    {category?.specializations?.map((spec, specIndex) => (
                                                                        <span key={specIndex} className="post1234forresume mr-4" style={{ marginBottom: "10px" }}>
                                                                            {spec}
                                                                        </span>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        ))}

                                                        {state2?.resume?.PersonalDetails?.profileSummary && (
                                                            <div style={{ marginTop: "10px", fontSize: "15px", color: "#666666" }}>
                                                                <div className="d-flex">
                                                                    <p style={{ fontWeight: "400", fontSize: "18px", color: "#666666" }}>Profile Summary</p>
                                                                </div>
                                                                <p style={{ fontSize: "16px", color: "#333333" }}>
                                                                    {(() => {
                                                                        const content = state2?.resume?.PersonalDetails?.profileSummary;
                                                                        const lines = content.split("\n");
                                                                        const isMultiLine = lines.length > 5;

                                                                        // Function to truncate text at word boundary
                                                                        const truncateText = (text) => {
                                                                            if (text.length <= 330) return text;
                                                                            let truncated = text.slice(0, 330);
                                                                            const lastSpace = truncated.lastIndexOf(" ");
                                                                            return lastSpace > 0 ? truncated.slice(0, lastSpace) : truncated;
                                                                        };

                                                                        if (isMultiLine) {
                                                                            // Handle multi-line content
                                                                            const visibleLines = isExpanded ? lines : lines.slice(0, 5);
                                                                            const joinedContent = visibleLines.join("\n");
                                                                            const truncatedContent = !isExpanded ? truncateText(joinedContent) : joinedContent;

                                                                            return (
                                                                                <>
                                                                                    <span
                                                                                        dangerouslySetInnerHTML={{
                                                                                            __html: truncatedContent.replace(/\n/g, "<br />"),
                                                                                        }}
                                                                                    />
                                                                                    {(lines.length > 5 || content.length > 330) && (
                                                                                        <>
                                                                                            {!isExpanded && <span>...</span>}
                                                                                            <span
                                                                                                onClick={() => setIsExpanded(!isExpanded)}
                                                                                                style={{
                                                                                                    color: "#2092c7",
                                                                                                    cursor: "pointer",
                                                                                                    textDecoration: "underline",
                                                                                                    marginLeft: "5px",
                                                                                                }}
                                                                                            >
                                                                                                {isExpanded ? "less" : "more"}
                                                                                            </span>
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            );
                                                                        } else {
                                                                            // Handle single paragraph content
                                                                            const truncatedContent = !isExpanded ? truncateText(content) : content;

                                                                            return (
                                                                                <>
                                                                                    <span
                                                                                        dangerouslySetInnerHTML={{
                                                                                            __html: truncatedContent.replace(/\n/g, "<br />"),
                                                                                        }}
                                                                                    />
                                                                                    {content.length > 330 && (
                                                                                        <>
                                                                                            {!isExpanded && <span>...</span>}
                                                                                            <span
                                                                                                onClick={() => setIsExpanded(!isExpanded)}
                                                                                                style={{
                                                                                                    color: "#2092c7",
                                                                                                    cursor: "pointer",
                                                                                                    textDecoration: "underline",
                                                                                                    marginLeft: "5px",
                                                                                                }}
                                                                                            >
                                                                                                {isExpanded ? "less" : "more"}
                                                                                            </span>
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            );
                                                                        }
                                                                    })()}
                                                                </p>
                                                            </div>
                                                        )}

                                                        {state2?.resume?.PersonalDetails?.availability && (
                                                            <div style={{ marginTop: "10px", fontSize: "15px", color: "black" }}>
                                                                <div className="d-flex">
                                                                    <p style={{ fontWeight: "400", fontSize: "18px", color: "#666666" }}>Availability</p>
                                                                </div>
                                                                <p style={{ marginTop: "-6px", fontSize: "16px", color: "black" }}>{state2?.resume?.PersonalDetails?.availability}</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            {/* Portfolio wrapper  */}

                            <div className="layoutDesign" id="portfolioWrapper">
                                <div className="row">
                                    <div className="col-12">
                                        <p style={{ color: "#333333", fontSize: "22px", letterSpacing: "0.5px", fontWeight: "700", marginTop: "15px" }}>Project Portfolio</p>
                                    </div>

                                    {state2?.resume?.Portfolio.length !== 0 && (
                                        <div className={`col-12 mobile-column-to-increase-width`}>
                                            <div className="portfolio-tabs">
                                                {/* display all portfolios */}
                                                <div className="tab-content">
                                                    <div id="published" className="tab-pane active">
                                                        <div className="row">
                                                            {state2?.profilePortfolio?.map((project) => (
                                                                <div
                                                                    className="col-md-4"
                                                                    key={project?.id}
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        positon: "relative",
                                                                    }}
                                                                    onMouseEnter={() => setHoveredProject(project?._id)}
                                                                    onMouseLeave={() => setHoveredProject(null)}
                                                                >
                                                                    <div
                                                                        className="card portcard"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#previewmodalresume"
                                                                        onClick={() => {
                                                                            setSelectedProject(project);
                                                                            handleAddEvent("Portfolio preview")
                                                                        }}
                                                                    >
                                                                        {/* <img className="card-img-top"  src={project.selectedThumbnail.url} alt={`Project ${project._id}`} /> */}
                                                                        <img className={`card-img-top project-image ${hoveredProject === project?._id ? "dim-image" : ""}`} src={project?.selectedThumbnail?.url} alt={`Project ${project._id}`} />
                                                                        <div className="card-body">
                                                                            <p
                                                                                className="card-text project-title"
                                                                                style={{ color: "#2092c7" }}
                                                                                data-bs-toggle="modal"
                                                                                data-bs-target="#previewmodalresume"
                                                                                onClick={() => {setSelectedProject(project);handleAddEvent("Portfolio preview")}}
                                                                            >
                                                                                {windowWidth > 1068 ? (project?.projectTitle.length > 60 ? project?.projectTitle.slice(0, 60) + "..." : project?.projectTitle) : windowWidth < 768 && windowWidth > 468 ? (project?.projectTitle.length > 100 ? project?.projectTitle.slice(0, 100) + "..." : project?.projectTitle) : windowWidth < 468 ? (project?.projectTitle.length > 60 ? project?.projectTitle.slice(0, 60) + "..." : project?.projectTitle) : project?.projectTitle.length > 40 ? project?.projectTitle.slice(0, 40) + "..." : project?.projectTitle}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        {/* custom paginATION */}

                                                        <div>{totalPages > 0 && <Pagination totalPages={totalPages} currentPage={activePage} onPageChange={setActivePage} />}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* Application wrapper  */}

                            <div className="layoutDesign" id="educationWrapper">
                                <>
                                    <div className="row d-flex justify-content-start align-items-center">
                                        <div className="col-12 col-md-12 d-flex justify-content-between align-items-center" style={{ minWidth: "150px" }}>
                                            <p style={{ color: "#333333", fontSize: "22px", letterSpacing: "0.5px", fontWeight: "700", marginTop: "15px" }}>Application</p>
                                            <p style={{ letterSpacing: "0.5px" }}>
                                                <span style={{ fontSize: "14px", marginTop: "-5px" }} className="aplied12 ">
                                                    {" "}
                                                    {"Applied "}
                                                    <span>{calculateDaysDifference(state2?.appliedDate).toFixed(0) == 0 ? "Today" : <>{timeAgo(state2?.appliedDate)}</>}</span>{" "}
                                                </span>
                                            </p>
                                        </div>

                                        <div className="col-12 py-3">
                                            <h3 className=" my-3" style={{ color: "#333333", fontWeight: "600" }}>
                                                Cover letter
                                            </h3>
                                            <p className=" mt-4 mb-1" style={{ fontWeight: "500", color: "#333333" }}>
                                                Why should you be hired for this project?
                                            </p>
                                            <p className="mt-2 mb-2" style={{ whiteSpace: "pre-wrap", color: "#666666" }}>
                                                {state2?.coverLetter}
                                            </p>

                                            <h3 className=" mb-3 mt-4" style={{ color: "#333333" }}>
                                                {role && role == "User" ? "Client" : "Your"} Budget
                                            </h3>
                                            <p className=" mt-2 mb-1" style={{ color: "#666666" }}>
                                                ${state2?.job.salary}
                                                {state2?.job.salaryMax !== null && ` - $${state2?.job.salaryMax} `} {state2?.job?.opportunityType !== "Hourly" ? `Fixed Cost` : `per hour`}
                                            </p>
                                            <h3 className=" mb-3 mt-4" style={{ color: "#333333" }}>
                                                Proposed Bid{" "}
                                            </h3>
                                            <div className="" style={{ display: "flex", alignItems: "center" }}>
                                                <p className=" mt-2 mb-2" style={{ display: "flex", alignItems: "center", color: "#666666" }}>
                                                    $
                                                    {bidchange && role === "User" ? (
                                                        <div  style={{ display: "flex" }}>
                                                            &nbsp;
                                                            <input className="updatebid" type="number" value={bid} disabled={!bidchange} style={{ paddingLeft: "10px" }} onChange={(e) => setbid(e.target.value)} />
                                                            {bidchange && role === "User" && (
                                                                <div className="updatebidButton">
                                                                    <button
                                                                        style={{
                                                                            background: "dodgerblue",
                                                                            color: "white",
                                                                            border: "none",
                                                                            padding: "5px",
                                                                        }}
                                                                        onClick={handleupdatebid}
                                                                    >
                                                                        Update
                                                                    </button>
                                                                </div>
                                                            )}
                                                            &nbsp;
                                                        </div>
                                                    ) : (
                                                        <>{bid}</>
                                                    )}
                                                    <span className="updatebidSpan">{state2?.job?.opportunityType !== "Hourly" ? ` Fixed Cost` : ` per hour`}</span>
                                                </p>
                                                {role === "User" && !bidchange && (
                                                    <RxPencil1
                                                        className="mx-2 resumePeronalDetailpencilicon"
                                                        style={{
                                                            color: "#2092c7",
                                                            cursor: "pointer",
                                                            fontSize: "20px",
                                                            marginBottom: "3px",
                                                        }}
                                                        onClick={() => setbidchange(!bidchange)}
                                                    />
                                                )}
                                            </div>
                                            <h3 className=" mb-3 mt-5" style={{ color: "#333333" }}>
                                                Project Category
                                            </h3>
                                            <p className=" mt-2 mb-2" style={{ color: "#666666" }}>
                                                {state2?.job.projectCategory}
                                            </p>

                                            <h3 className=" mb-3 mt-5" style={{ color: "#333333" }}>
                                                Availability
                                            </h3>
                                            <p className=" my-3" style={{ color: "#666666" }}>
                                                {state2?.availability == true ? (
                                                    <>
                                                        {" "}
                                                        Yes, I am available for {state2?.job?.duration} {state2?.job?.durationType} starting immediately for a {state2?.job?.isPartTimeAllowed === "yes" || state2?.job?.isPartTimeAllowed === "Yes" || state2?.job?.isPartTimeAllowed === "YES" ? <>part-time</> : <>full-time</>} {state2?.job?.opportunityType.toLowerCase()}
                                                    </>
                                                ) : (
                                                    <> No, {state2?.specifyAvailability}</>
                                                )}
                                            </p>

                                            {state2?.assessment && state2?.assessment.length > 0 ? (
                                                <>
                                                    <h3 className=" mb-4 mt-5" style={{ color: "#333333" }}>
                                                        Assessment
                                                    </h3>

                                                    {state2?.assessment.map((data, index) => (
                                                        <>
                                                            <p className=" mt-4 mb-2" style={{ fontWeight: "500", color: "#666666" }}>
                                                                Q{index + 1}. {data.Question}
                                                            </p>
                                                            <p className=" mb-3 mt-2" style={{ color: "#666666" }}>
                                                                {data.Answer}
                                                            </p>
                                                        </>
                                                    ))}
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </div>
                                </>
                            </div>
                            {/* education wrapper  */}
                            <div className="layoutDesign" id="educationWrapper">
                                <>
                                    <div className="row d-flex justify-content-start align-items-center">
                                        <div className="col-12 col-md-12 d-flex justify-content-start align-items-center" style={{ minWidth: "150px" }}>
                                            <p style={{ color: "#333333", fontSize: "22px", letterSpacing: "0.5px", fontWeight: "700", marginTop: "15px" }}>Education</p>
                                        </div>

                                        {state2 && state2?.resume.Education.length !== 0 && (
                                            <div className="col-12 col-md-12 ">
                                                <>
                                                    {state2?.resume.Education.map((data) => {
                                                        return (
                                                            <>
                                                                <div className="row" style={{ marginBottom: "10px" }}>
                                                                    <div className="col-md-12 col-12">
                                                                        <div style={{ color: "#4A4E50" }}>
                                                                            <p style={{ fontSize: "15px", color: "#404040" }}>
                                                                                <b>{data.degree}</b>
                                                                            </p>
                                                                            <div style={{ color: "#6E6E6E", fontSize: "15px", marginTop: "-8px" }}>
                                                                                <p>{data.school}</p>
                                                                                {data.specialization ? <p style={{ marginTop: "-8px" }}>({data.specialization})</p> : ""}
                                                                                <p style={{ marginTop: "-8px" }}>
                                                                                    {data.startDate.slice(0, 4)} - {data.endDate.slice(0, 4)}
                                                                                </p>
                                                                                <p style={{ marginTop: "-8px" }}>
                                                                                    {data.performanceScale}: {data.performance}
                                                                                    {data.performanceScale === "Percentage" && "%"}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        );
                                                    })}
                                                </>
                                            </div>
                                        )}
                                    </div>
                                </>
                            </div>

                            {/* work experience wrapper */}
                            <div className="layoutDesign" id="workExperienceWrapper">
                                <>
                                    <div className="row d-flex justify-content-start align-items-center">
                                        <div className="col-10 col-md-11">
                                            <p style={{ color: "#333333", fontSize: "22px", letterSpacing: "0.5px", fontWeight: "700", margin: "8px 0px" }}>Work Experience {state2 && state2?.resume?.Job && calculateTotalExperience(state2?.resume?.Job, state2?.resume?.Internship) && `${calculateTotalExperience(state2?.resume?.Job, state2?.resume?.Internship)}`}</p>
                                        </div>

                                        {state2 && state2?.resume?.Job.length !== 0 && (
                                            <div className="col-md-12 col-12 mobile-column-to-increase-width">
                                                <>
                                                    {state2?.resume?.Job.map((data) => {
                                                        return (
                                                            <>
                                                                <div className="row" style={{ marginBottom: "10px" }}>
                                                                    <div className="col-md-12 col-12">
                                                                        <div>
                                                                            <p style={{ fontSize: "15px", color: "#404040" }}>
                                                                                <b>{data.profile} </b>
                                                                            </p>
                                                                            <div style={{ color: "#6E6E6E", fontSize: "15px", marginTop: "-8px" }}>
                                                                                <p style={{ marginTop: "-8px" }}>
                                                                                    {data.organization}, {data.location}
                                                                                </p>
                                                                                <p style={{ marginTop: "-8px" }}>
                                                                                    Job <RxDotFilled style={{ color: "#464545" }} /> {MONTH_DETAILS[parseInt(data.startDate.slice(5, 7), 10)] + " " + data.startDate.slice(0, 4)} - {MONTH_DETAILS[parseInt(data.endDate.slice(5, 7), 10)] + " " + data.endDate.slice(0, 4)} {<MonthCalculator startDate={data.startDate} endDate={data.endDate} />}
                                                                                </p>

                                                                                <SeeMoreLess text={data?.description} maxLength={350} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        );
                                                    })}
                                                </>
                                            </div>
                                        )}
                                    </div>
                                </>
                            </div>

                            {/* skills wrapper */}
                            <div className="layoutDesign" id="skillsWrapper" style={{ marginBottom: "20px" }}>
                                <>
                                    <div className="row d-flex justify-content-start align-items-center">
                                        <div className="col-10 col-md-11 d-flex justify-content-start align-items-center" style={{ minWidth: "150px" }}>
                                            <p style={{ color: "#333333", fontSize: "22px", letterSpacing: "0.5px", fontWeight: "700", marginTop: "15px" }}>Skills</p>
                                        </div>

                                        {state2 && state2?.resume?.Skills.length !== 0 && (
                                            <div className="card skills-card">
                                                <>
                                                    <div className="row g-4" style={{ pointerEvents: "none" }}>
                                                        {state2?.resume?.Skills.map((data) => (
                                                            <div key={data._id} className="col-12 col-md-6">
                                                                <div className="skill-item">
                                                                    <div className="row align-items-start">
                                                                        <div className="col-9 col-sm-8">
                                                                            <h3 className="skill-name">{data.name}</h3>
                                                                            <p className="skill-level">{data.level}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            </div>
                                        )}
                                    </div>
                                </>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "end",
                                    maxWidth: "1200px",
                                    margin: "auto",
                                }}
                            >
                                <div>
                                    {role && role !== "User" && state2?.status !== "Hired" && state2?.status !== "Shortlisted" && (
                                        <>
                                            <button className=" shortcut-newappliedjob" style={{ color: "#1177d6", border: "1px solid #1177d6" }} onClick={() => handleUpdateStatus("Shortlisted")}>
                                                <>{state2?.status !== "Hired" && state2?.status !== "Shortlisted" && <>Shortlist</>}</>
                                            </button>
                                        </>
                                    )}
                                    {role && role !== "User" && state2?.status !== "Hired" && (
                                        <>
                                            <button className=" shortcut-newappliedjob" style={{ color: "#1177d6", border: "1px solid #1177d6" }} onClick={() => handleUpdateStatus("Hired")}>
                                                <>{state2?.status !== "Hired" && <>Hire</>}</>
                                            </button>
                                        </>
                                    )}

                                    {role && role !== "User" ? (
                                        <button
                                            className="shortcut-newappliedjob"
                                            style={{ color: "#1177d6", border: "1px solid #1177d6" }}
                                            // onClick={() => handleChatClick(state2?.user?._id, state2?.employer, state2?.job?._id, state2?._id , navigate)}
                                            onClick={() => {
                                                setOpenPopup(!openPopup);
                                                handleAddEvent("Message")
                                            }}
                                        >
                                            Message
                                        </button>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="" style={{ textAlign: "center", paddingBottom: "220px" }}>
                    <p
                        style={{
                            color: "#4B92C8",
                            fontWeight: "500",
                            fontSize: "20px",
                            marginLeft: "43px",
                            marginBottom: "5px",
                            marginTop: "220px",
                        }}
                    >
                        Loading..
                    </p>
                    <PropagateLoader color={"#4B92C8"} loading={true} size={15} aria-label="Loading Spinner" data-testid="loader" style={{ marginLeft: "27px" }} />
                </div>
            )}

            <SimpleDialogNew
                candidates={state2?.user?._id} //userid
                appliedJobId={state2?.job?._id} //jobid
                employerId={state2?.employer}
                mainId={state2?._id}
                open={openPopup}
                onClose={() => setOpenPopup(false)}
            />
            <ToastContainer position="top-center" autoClose={10000} />
        </>
    );
};

export default AppliedJobReview;
