import React, { useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { FaBookmark } from "react-icons/fa";
import list from "../../assets/images/list.png";
import { useNavigate } from "react-router-dom";
import PropagateLoader from "react-spinners/PropagateLoader";
import { CgFileDocument } from "react-icons/cg";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import { logEvent } from "../../utils/Analytics";
import { useSelector } from "react-redux";
const StudentSavedjob = () => {
    const {user} = useSelector((state) => state.user)
    const openPreviewPageInNewTab = (data) => {
        logEvent("Button", `Clicked view project button`, user ? user.role : "Guest");
        
        // Create sanitized versions of each component
        const sanitizedProfile = data.profile?.replace(/[^\w\s-]/g, "-").replace(/\s+/g, "-").replace(/-+/g, "-") || "";
        const sanitizedOpportunityType = data.opportunityType?.replace(/[^\w\s-]/g, "-").replace(/\s+/g, "-").replace(/-+/g, "-") || "";
        const sanitizedOrgName = data.organisationName?.replace(/[^\w\s-]/g, "-").replace(/\s+/g, "-").replace(/-+/g, "-") || "";
        
        // Construct URL with sanitized components
        const url = `/preview/${sanitizedProfile}-${sanitizedOpportunityType}-at-${sanitizedOrgName}_${data._id}`.toLowerCase();
        
        // Open URL in new tab
        window.open(url);
    };

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo(0, 0);
    }, []);
    const [matchedJobs, setMatchedJobs] = useState([]);
    const [jobData, setJobData] = useState("");
    const [count, setCount] = useState("");
    const [savedJobs, setSavedJobs] = useState("");
    const [page, setPage] = useState(1);
    const [forceRender, setForceRender] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const getSavedJobs = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get("/api/user/getSavedJobs", {
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token"),
                },
            });
            setSavedJobs(data.savedJobs);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    const getUserData = async (page) => {
        try {
            const { data } = await axios.post(
                "/api/employer/getAllJobs",
                { page },
                {
                    headers: {
                        "Content-Type": "application/json",
                        token: localStorage.getItem("token"),
                    },
                }
            );
            setCount(data.count);
            setJobData(data.job);
            // Find and store matched jobs based on saved job IDs
            const matchedJobsData = data.job.filter((job) => savedJobs.includes(job._id));
            setMatchedJobs(matchedJobsData);
        } catch (error) {
            console.log(error.response.data);
        }
    };

    useEffect(() => {
        getSavedJobs();
        getUserData(page);
    }, [page, forceRender]);

    const handleRemoveJob = async (jobId) => {
        console.log(`Attempting to remove job with ID: ${jobId}`);
        try {
            const { data } = await axios.get(`/api/user/removeJob/${jobId}`, {
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token"),
                },
            });

            console.log("Response from removeJob API:", data);

            if (data.success) {
                logEvent("Button", `Clicked removed project button`, user ? user.role : "Guest");
                setMatchedJobs((prevJobs) => prevJobs.filter((job) => job._id !== jobId));
                setForceRender((prev) => !prev);
            } else {
                console.log("Failed to remove job");
            }
        } catch (error) {
            console.error("Error in handleRemoveJob:", error);
        }
    };

    return (
        <div>
            <div className="my-applications">
                {loading ? (
                    <>
                        <div className="my-auto" style={{ textAlign: "center" }}>
                            <p style={{ color: "#4B92C8", fontWeight: "500", marginTop: "-15px", fontSize: "20px", marginLeft: "43px", marginBottom: "5px" }}>Loading..</p>
                            <PropagateLoader color={"#4B92C8"} loading={true} size={15} aria-label="Loading Spinner" data-testid="loader" style={{ marginLeft: "27px" }} />
                        </div>
                    </>
                ) : (
                    <>
                        {savedJobs && savedJobs.length > 0 ? (
                            <>
                                <h2 className="title">Saved Projects</h2>
                                
                                <TableContainer component={Paper} style={{ display: "flex", justifyContent: "center", alignItems: "center", boxShadow: "none",backgroundColor:"transparent" }}>
                                    <Table sx={{ minWidth: 650, maxWidth: 1100, borderRadius: 3 }} aria-label="saved jobs table" style={{ borderRadius: "10px", overflow: "hidden",boxShadow:"0px 0px 15px 0px #00000025" }}>
                                        <TableHead >
                                            <TableRow >
                                                <TableCell style={{textAlign:"start",backgroundColor:"#4b92c8", fontSize:"15px",color:"#fff",width:"200px", textTransform: "capitalize" }}>Project Type</TableCell>
                                                <TableCell style={{textAlign:"start",backgroundColor:"#4b92c8", fontSize:"15px",color:"#fff",width:"400px", textTransform: "capitalize", }}>Profile</TableCell>
                                                <TableCell style={{textAlign:"center",backgroundColor:"#4b92c8", fontSize:"15px",color:"#fff", textTransform: "capitalize" }}>Number Of Applicants</TableCell>
                                                <TableCell style={{textAlign:"center",backgroundColor:"#4b92c8", fontSize:"15px",color:"#fff", textTransform: "capitalize" }}>View Project</TableCell>
                                                <TableCell style={{textAlign:"center",backgroundColor:"#4b92c8", fontSize:"15px",color:"#fff", textTransform: "capitalize" }}>Remove Project</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Array.isArray(savedJobs) &&
                                                savedJobs.map((job, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell style={{ fontSize: "13px", textAlign: "start", paddingLeft: "30px" }}>{job.opportunityType}</TableCell>
                                                        <TableCell style={{ fontSize: "13px", textAlign: "start",  }}>{job.profile}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: "13px" }}>
                                                            {job.userApplied.length}
                                                        </TableCell>
                                                        <TableCell align="center" style={{ textAlign: "center" }}>
                                                            <IconButton onClick={() => openPreviewPageInNewTab(job)}>
                                                                <CgFileDocument style={{ cursor: "pointer", fontSize: "18px" }} />
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell align="center" style={{ textAlign: "center" }}>
                                                            <IconButton onClick={() => handleRemoveJob(job._id)}>
                                                                <FaBookmark style={{ fontSize: "18px", color: "#0991DA", cursor: "pointer" }} />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        ) : (
                            <>
                                <div className="text-center my-auto">
                                    <i className="fa fa-window-close-o mx-auto" aria-hidden="true" style={{ fontSize: "200px" }}></i>
                                    <h1>Projects not Found</h1>
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default StudentSavedjob;
