import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import "../Student_New/UserApplications.scss";
// import "../Student_New/Jobs.scss";
import PropagateLoader from "react-spinners/PropagateLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoIosArrowForward, IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { MONTH_DETAILS } from "../data";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "react-select";
import ScaleLoader from "react-spinners/ScaleLoader";
import { Grid, Tooltip, Typography } from "@mui/material";
import "./AdminDashboard.css";
import { LuClipboardEdit } from "react-icons/lu";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { loginUserForAdminCandidate } from "../../actions/userActions";
import { RiDeleteBinLine } from "react-icons/ri";
import Popup from "reactjs-popup";
import Pagination from "../Student_New/PaginationWithLastButton";
import PaginationWithLastButton from "../Student_New/PaginationWithLastButton";
// import { Button, Grid, MenuItem, Pagination as MuiPagination, TextField,  } from "@mui/material";

function AdminCandidates() {
    const [currentPage, setCurrentPage] = useState(1);
    const [candidateData, setCandidateData] = useState("");
    const [totalItem, setTotalItem] = useState(0);
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(false);
    const [sortByJobsApplied, setSortByJobsApplied] = useState(null);
    const [sortByJobsRejected, setSortByJobsRejected] = useState(null);
    const [sortByJobsShortlisted, setSortByJobsShortlisted] = useState(null);
    const [sortByJobsHired, setSortByJobsHired] = useState(null);
    const [sortField, setSortField] = useState(null); // State for sorting field
    const [sortOrder, setSortOrder] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loginType, setLoginType] = useState("User");
    const { isAuthenticated, user } = useSelector((state) => state.user);
    const [totalPages, setTotalPages] = useState(1);
    const [totalData, setTotalData] = useState(null);
    const borderRemoveRef = useRef(null);

    const handleLogin = async (userId) => {
        if (loginType === "User") {
            // Assuming loginUser is an action creator
            await dispatch(loginUserForAdminCandidate(userId, loginType));
            localStorage.setItem("userType", loginType);
            localStorage.setItem("userLoggedIn", true);
            const userInfo = await axios.get("/api/user/me", {
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token"),
                },
            });
            console.log(userInfo);
            const basicDetails = {
                name: userInfo?.data?.user?.name,
                email: userInfo?.data?.user?.email,
            };
            localStorage.setItem("basicDetails", JSON.stringify(basicDetails));
            navigate("/");
        }
    };

    useEffect(() => {
        if (localStorage.getItem("token")) {
            if (user?.role === "User") {
                navigate("/");
            } else if (user?.role === "Employer") {
                navigate("/employerApplication/Internship");
            }
        }
    }, [isAuthenticated, user, navigate]);
    

    const GetCandidateData = async (searchInput = "") => {
        console.log("Candidate data function");
        setLoading(true);
    
        try {
            const { data } = await axios.post("/api/user/getUserDataForAdmin", {
                searchInput,
                page: currentPage,
                pageSize: 15, 
                sortBy: sortField,  // Always send the sort field
                sortOrder: sortOrder, // Always send the sort order
            });
    
            setLoading(false);
    
            // Check if we need to handle client-side sorting for special fields
            let processedData = data.data;
            
            if (["jobsRejected", "jobsApplied", "jobsShortlisted", "jobsHired", "fixedJobs", "hourlyJobs", "connectSpent", "connectCredit", "viewingFreelancer"].includes(sortField)) {
                processedData = [...processedData].sort((a, b) => {
                    let valueA, valueB;
    
                    if (sortField === "jobsApplied") {
                        valueA = a.appliedJobs ? a.appliedJobs.length : 0;
                        valueB = b.appliedJobs ? b.appliedJobs.length : 0;
                    } else if (sortField === "jobsRejected") {
                        valueA = a.appliedJobs ? a.appliedJobs.filter(job => job.status === "NotIntrested").length : 0;
                        valueB = b.appliedJobs ? b.appliedJobs.filter(job => job.status === "NotIntrested").length : 0;
                    } else if (sortField === "jobsShortlisted") {
                        valueA = a.appliedJobs ? a.appliedJobs.filter(job => job.status === "Shortlisted").length : 0;
                        valueB = b.appliedJobs ? b.appliedJobs.filter(job => job.status === "Shortlisted").length : 0;
                    } else if (sortField === "jobsHired") {
                        valueA = a.appliedJobs ? a.appliedJobs.filter(job => job.status === "Hired").length : 0;
                        valueB = b.appliedJobs ? b.appliedJobs.filter(job => job.status === "Hired").length : 0;
                    } else if (sortField === "fixedJobs") {
                        valueA = a.appliedJobs ? a.appliedJobs.filter(appliedJob => appliedJob.job && appliedJob.job.opportunityType === "Fixed Cost").length : 0;
                        valueB = b.appliedJobs ? b.appliedJobs.filter(appliedJob => appliedJob.job && appliedJob.job.opportunityType === "Fixed Cost").length : 0;
                    } else if (sortField === "hourlyJobs") {
                        valueA = a.appliedJobs ? a.appliedJobs.filter(appliedJob => appliedJob.job && appliedJob.job.opportunityType === "Hourly").length : 0;
                        valueB = b.appliedJobs ? b.appliedJobs.filter(appliedJob => appliedJob.job && appliedJob.job.opportunityType === "Hourly").length : 0;
                    } else if (sortField === "connectSpent") {
                        valueA = a.connectSpent ? a.connectSpent : 0;
                        valueB = b.connectSpent ? b.connectSpent : 0;
                    } else if (sortField === "connectCredit") {
                        valueA = a.connectCredit ? a.connectCredit : 0;
                        valueB = b.connectCredit ? b.connectCredit : 0;
                    }else if (sortField === "viewingFreelancer") {
                        valueA = a.connectSpentJobSeeker ? a.connectSpentJobSeeker : 0;
                        valueB = b.connectSpentJobSeeker ? b.connectSpentJobSeeker : 0;
                    }
    
                    // Apply sorting direction
                    return sortOrder === "asc" ? valueA - valueB : valueB - valueA;
                });
            }
    
            setCandidateData(processedData);
            setTotalPages(data.totalPages);
            setTotalItem(data.totalItems);
        } catch (error) {
            setLoading(false);
            toast.error(error.response?.data || "An error occurred", {
                style: {
                    padding: "18px",
                },
            });
        }
    };
    
    // Helper functions for job counting
    const countHourlyJobs = (appliedJobs) => {
        return appliedJobs?.filter(job => job.job?.opportunityType === "Hourly").length || 0;
    };
    
    const countFixedCost = (appliedJobs) => {
        return appliedJobs?.filter(job => job.job?.opportunityType === "Fixed Cost").length || 0;
    };
    
    const countRejectedJobs = (appliedJobs) => {
        return appliedJobs?.filter(job => job.status === "NotIntrested").length || 0;
    };
    
    const countShortlistedJobs = (appliedJobs) => {
        return appliedJobs?.filter(job => job.status === "Shortlisted").length || 0;
    };
    
    const countHiredJobs = (appliedJobs) => {
        return appliedJobs?.filter(job => job.status === "Hired").length || 0;
    };
    
    // Function to handle sorting
    // const handleSort = (field, order) => {
    //     setSortField(field);
    //     setSortOrder(order);
    //     // No need to call API for sorting since we'll do it on the frontend
    // };
    
    // Function to get sorted data based on current sort field and order
    const getSortedData = () => {
        if (!candidateData || candidateData.length === 0) return [];
        
        const sortedData = [...candidateData]; // Create a copy to avoid mutating state
        
        switch(sortField) {
            case "hourlyJobs":
                sortedData.sort((a, b) => {
                    const countA = countHourlyJobs(a.appliedJobs);
                    const countB = countHourlyJobs(b.appliedJobs);
                    return sortOrder === "asc" ? countA - countB : countB - countA;
                });
                break;
                
            case "fixedJobs":
                sortedData.sort((a, b) => {
                    const countA = countFixedCost(a.appliedJobs);
                    const countB = countFixedCost(b.appliedJobs);
                    return sortOrder === "asc" ? countA - countB : countB - countA;
                });
                break;
                
            case "jobsRejected":
                sortedData.sort((a, b) => {
                    const countA = countRejectedJobs(a.appliedJobs);
                    const countB = countRejectedJobs(b.appliedJobs);
                    return sortOrder === "asc" ? countA - countB : countB - countA;
                });
                break;
                
            case "jobsShortlisted":
                sortedData.sort((a, b) => {
                    const countA = countShortlistedJobs(a.appliedJobs);
                    const countB = countShortlistedJobs(b.appliedJobs);
                    return sortOrder === "asc" ? countA - countB : countB - countA;
                });
                break;
                
            case "jobsHired":
                sortedData.sort((a, b) => {
                    const countA = countHiredJobs(a.appliedJobs);
                    const countB = countHiredJobs(b.appliedJobs);
                    return sortOrder === "asc" ? countA - countB : countB - countA;
                });
                break;
                
            case "connectCredit":
                sortedData.sort((a, b) => {
                    const creditA = parseInt(a.connectCredit || 0);
                    const creditB = parseInt(b.connectCredit || 0);
                    return sortOrder === "asc" ? creditA - creditB : creditB - creditA;
                });
                break;
                
            case "connectSpent":
                sortedData.sort((a, b) => {
                    const spentA = parseInt(a.connectSpent || 0);
                    const spentB = parseInt(b.connectSpent || 0);
                    return sortOrder === "asc" ? spentA - spentB : spentB - spentA;
                });
                break;
                
            default:
                // Default sorting by date added
                sortedData.sort((a, b) => {
                    const dateA = new Date(a.dataAdded);
                    const dateB = new Date(b.dataAdded);
                    return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
                });
        }
        
        return sortedData;
    };
    const getTotalData = async () => {
        try {
            const response = await axios.get("/api/user/getTotalUserAndJobs");
            setTotalData(response?.data);
            console.log(response?.data);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        getTotalData();
    }, [candidateData]);

    useEffect(() => {
        GetCandidateData();
    }, [sortField, sortOrder]);
    // const handleSortnew = (field, direction) => {
    //     console.log(field, direction);
    //     setSortField(field);
    //     setSortOrder(direction);
    //     handleSort(field, direction); // Sort data manually
    // };
    const handleSort = (field, order) => {
        console.log(field, order);
        setSortField(field);
        setSortOrder(order);
        setCurrentPage(1); 
    
        // Handle frontend sorting for job-related fields
        if (["jobsApplied", "jobsRejected", "jobsShortlisted", "jobsHired", "fixedJobs", "hourlyJobs","connectSpent","connectCredit","viewingFreelancer"].includes(field)) {
            const sortedData = [...candidateData].sort((a, b) => {
                let valueA, valueB;
    
                if (field === "jobsApplied") {
                    valueA = a.appliedJobs ? a.appliedJobs.length : 0;
                    valueB = b.appliedJobs ? b.appliedJobs.length : 0;
                } else if (field === "jobsRejected") {
                    valueA = countRejectedJobs(a.appliedJobs);
                    valueB = countRejectedJobs(b.appliedJobs);
                } else if (field === "jobsShortlisted") {
                    valueA = countShortlistedJobs(a.appliedJobs);
                    valueB = countShortlistedJobs(b.appliedJobs);
                } else if (field === "jobsHired") {
                    valueA = countHiredJobs(a.appliedJobs);
                    valueB = countHiredJobs(b.appliedJobs);
                } else if (field === "fixedJobs") {
                    valueA = countFixedCost(a.appliedJobs);
                    valueB = countFixedCost(b.appliedJobs);
                } else if (field === "hourlyJobs") {
                    valueA = countHourlyJobs(a.appliedJobs);
                    valueB = countHourlyJobs(b.appliedJobs);
                }
    
                return order === "asc" ? valueA - valueB : valueB - valueA;
            });
    
            // Apply pagination after sorting
            const startIndex = (currentPage - 1) * 15;
            setCandidateData(sortedData.slice(startIndex, startIndex + 15));
        } else {
            // Backend sorting for other fields
            GetCandidateData("");
        }
    };
    
    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    useEffect(() => {
        GetCandidateData();
        window.scrollTo(0, 0);
    }, [currentPage]);



    // ACTIVE DEACTIVE OPTION:

    const [id, setId] = useState("");
    const [userStatus, setUserStatus] = useState("");

    const idSelector = (_id) => {
        setId(_id);
    };

    const ActiveUser = async () => {
        window.scrollTo(0, 0);
        setLoading(true);
        try {
            const { data } = await axios.post("/api/user/activedeactive", {
                id: id,
                status: "active",
            });
            // GetCandidateData();
            // setCandidateData(candidateData?.map(user => user._id === id ? { ...user, active: "Active" } : user));
            const userIndex = candidateData.findIndex((user) => user._id === id);
            // If the user is found, update the active field directly
            if (userIndex !== -1) {
                candidateData[userIndex].active = true;
            }
            toast.success("User is active now");
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
            toast.error(error.response.data, {
                style: {
                    padding: "18px",
                },
            });
        }
    };

    const DeActiveUser = async () => {
        window.scrollTo(0, 0);
        setLoading(true);
        try {
            const { data } = await axios.post("/api/user/activedeactive", {
                id: id,
                status: "de-active",
            });
            // GetCandidateData();
            // setCandidateData(candidateData?.map(user => user._id === id ? { ...user, active: "De-active" } : user));
            const userIndex = candidateData.findIndex((user) => user._id === id);
            // If the user is found, update the active field directly
            if (userIndex !== -1) {
                candidateData[userIndex].active = false;
            }
            toast.success("User is not active now");
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
            toast.error(error.response.data, {
                style: {
                    padding: "18px",
                },
            });
        }
    };

    // SEARCH OPTION:

    const [search, setSearch] = useState("");
    const handleSearchInput = (e) => {
        setSearch(e.target.value);
    };

    // const handleSearch = () =>{
    //   GetCandidateData();
    //   setSearch("");
    // }
    const handleSearch = () => {
        console.log("handle search ");

        if (search.trim() !== "") {
            // Only call GetJobData with search input if it's not empty
            GetCandidateData(search.trim());
            setSearch("");
        } else {
            setCurrentPage(1);
            if (currentPage !== 1) {
                // Check if current page is not already 1
                setCurrentPage(1); // Set current page to 1
            }
            GetCandidateData(); // If search input is empty, fetch all data
            // setCurrentPage(1, () => {
            //   GetCandidateData("");
            // });
            setSearch("");
        }
    };
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            handleSearch();
        }
    };

    // FOR AUTHENTICATION
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        if (!localStorage.getItem("token") || !localStorage.getItem("userType") || localStorage.getItem("userType") !== "Admin") {
            navigate("/admin_login");
        }
    });
    //  END

    const initialForm = {
        _id: "",
        name: "",
        phone: "",
        city: "",
        availability: "",
    };

    const [candidate, setCandidate] = useState(initialForm);
    useEffect(() => {
        borderRemoveRef.current.scrollTop = 0;
    }, [candidate._id]);
    const fetchData = async (_id) => {
        borderRemoveRef.current.scrollTop = 0;
        const response = await fetch(`/api/admin/getUserToUpdatedata/${_id}`);
        if (!response.ok) {
            throw new Error("Failed to fetch user data");
        }
        const data = await response.json();

        // console.log(data, "user data");
        setCandidate({
            _id: data.user._id,
            name: data.user.name || "",
            phone: data.user.phone || "",
            city: data.user.city || "",
            availability: data.user.availability || "",
            email: data.user.email,
        });
    };
    console.log(candidateData);

    const handleUserChange = (e) => {
        setCandidate({
            ...candidate,
            [e.target.name]: e.target.value,
        });
    };
    const handleUpdate = async () => {
        setLoading(true);
        if (!candidate.name) {
            setLoading(false);
            toast.error("Name is required!!");
        } else if (!candidate.phone) {
            setLoading(false);
            toast.error("Phone is required!!");
        } else if (!candidate.city) {
            setLoading(false);
            toast.error("City is required!!");
        } else if (candidate.availability == "Select availability") {
            setLoading(false);
            toast.error("Select availability!!");
        } else {
            // console.log(candidate.email, "email");
            try {
                const { data } = await axios.post("/api/admin/udateuserdatafromadminsite", {
                    name: candidate.name,
                    phone: candidate.phone,
                    city: candidate.city,
                    availability: candidate.availability,
                    email: candidate.email,
                });
                setLoading(false);
                toast.success("User Updated Successfully!", {
                    style: {
                        // padding: "18px",
                    },
                });
                document.querySelector(".btn-close").click();
                GetCandidateData();
                setCandidate({
                    name: "",
                    phone: "",
                    city: "",
                    availability: "Select availability",
                });
            } catch (error) {
                setLoading(false);
                toast.error(error.response.data, {
                    style: {
                        padding: "18px",
                    },
                });
            }
        }
    };

    const handleKeyDown3 = (e, id) => {
        if (e.key === "Enter") {
            e.preventDefault();
            const newConnectCredit = e.target.textContent.trim(); // Get the edited content
            handleBalanceUpdate(newConnectCredit, id); // Pass the new value and the id to the update function
        }
    };
    const handleBlur = (e, id) => {
        const newConnectCredit = e.target.textContent.trim(); // Get the edited content
        handleBalanceUpdate(newConnectCredit, id); // Pass the new value and the id to the update function
    };
    const handleBlur2 = (e, id) => {
        const newConnectSpent = e.target.textContent.trim();
        handleBalanceSpentUpdate(newConnectSpent, id);
    };

    const handleBalanceUpdate = async (newConnectCredit, id) => {
        // e.preventDefault();
        // setLoading(true);
        if (!newConnectCredit) {
            // setLoading(false);
            toast.error("Balance Connect is required!!");
        } else {
            try {
                const { data } = await axios.post("/api/admin/updatebalanceconnectadminemployee", {
                    // connectCredit:balanceConnect.connectCredit,
                    connectCredit: newConnectCredit,
                    // _id:balanceConnect._id
                    _id: id,
                });
                // setLoading(false);
                // toast.success("Balance Connect Updated Successfully!", {
                //   style: {
                //     // padding: "18px",
                //   },
                // });
                document.querySelector(".close2").click();
                const userIndex = candidateData.findIndex((user) => user._id === id);
                if (userIndex !== -1) {
                    candidateData[userIndex].connectCredit = newConnectCredit;
                }
                // GetEmployerData();
            } catch (error) {
                setLoading(false);
                toast.error(error.response.data, {
                    style: {
                        padding: "18px",
                    },
                });
            }
        }
    };
    const handleKeyDown4 = (e, id) => {
        if (e.key === "Enter") {
            e.preventDefault();
            const newConnectSpent = e.target.textContent.trim();
            handleBalanceSpentUpdate(newConnectSpent, id);
        }
    };
    const handleBalanceSpentUpdate = async (newConnectSpent, id) => {
        // e.preventDefault();
        // setLoading(true);
        if (!newConnectSpent) {
            // setLoading(false);
            toast.error("Connect Spent is required!!");
        } else {
            try {
                const { data } = await axios.post("/api/admin/updateconnectspentadminemployee", {
                    connectSpent: newConnectSpent,
                    _id: id,
                    // connectSpent:balanceConnect.connectSpent,
                    // _id:balanceConnect._id
                });
                // setLoading(false);
                // toast.success("Balance Connect Updated Successfully!", {
                //   style: {
                //     // padding: "18px",
                //   },
                // });
                document.querySelector(".close3").click();
                const userIndex = candidateData.findIndex((user) => user._id === id);
                if (userIndex !== -1) {
                    candidateData[userIndex].connectSpent = newConnectSpent;
                }
                // GetEmployerData();
            } catch (error) {
                setLoading(false);
                toast.error(error.response.data, {
                    style: {
                        padding: "18px",
                    },
                });
            }
        }
    };

    // console.log(countJobTypes(candidateData));

    const [showModal, setShowModal] = useState(false);
    const [idToDelete, setIdToDelete] = useState(null);

    const handleDeleteConfirmation = (id) => {
        setIdToDelete(id);
        setShowModal(true);
    };

    const handleConfirmDelete = async () => {
        await handleDeleteCandidate(idToDelete);
        setShowModal(false);
    };

    const handleCancelDelete = () => {
        setShowModal(false);
    };
    const handleDeleteCandidate = async (id) => {
        setLoading(true);
        try {
            await axios.post(`/api/admin/deleteUserFromAdminside/${id}`);

            const canIndex = candidateData.findIndex((job) => job._id === id);

            if (canIndex !== -1) {
                const updatedCanData = [...candidateData];
                updatedCanData.splice(canIndex, 1);
                setCandidateData(updatedCanData);
            }
            toast.success("User Deleted Successfully");
            setLoading(false);
            await axios.post("/api/admin/logDeleteAttempt", { userId: id });
        } catch (error) {
            console.error("Error deleting job:", error);
        }
    };
    console.log(candidateData);

    return (
        <>
            <div className="my-applications">
                {loading ? (
                    <>
                        <div className="my-auto" style={{ textAlign: "center" }}>
                            <p
                                className="loaderLoader"
                                style={{
                                    color: "#4B92C8",
                                    fontWeight: "500",
                                    fontSize: "20px",
                                    marginLeft: "16px",
                                    marginBottom: "5px",
                                }}
                            >
                                Loading..
                            </p>
                            <PropagateLoader color={"#4B92C8"} loading={true} size={15} aria-label="Loading Spinner" data-testid="loader" style={{ marginLeft: "-4px" }} />
                        </div>
                    </>
                ) : (
                    <>
                        <p className="borderremove" style={{ color: "#494949", fontSize: "20px", fontWeight: "500", backgroundColor: "white", borderRadius: "10px", paddingLeft: "10%", paddingRight: "10%", paddingTop: "1%", paddingBottom: "1%",boxShadow:"0px 0px 15px 0px #00000025" }}>
                            Freelancer Details
                        </p>
                        <div className="AdminSearchBox">
                            <input className="AdminSearchInput" type="text" placeholder="Search by name, email, phone" onChange={handleSearchInput} value={search} onKeyPress={handleKeyPress} />
                            <button className="AdminSearchButton" onClick={handleSearch}>
                                Search
                            </button>
                        </div>
                        <div className="table-box table-responsive border borderremove px-0">
                            <table className="applications table" style={{ marginLeft: "-2px" }}>
                                <thead>
                                    <tr>
                                        <th className="tableHead">Name</th>
                                        <th>
                                            <div style={{ width: "100px", textAlign: "center" }}>Rate</div>
                                        </th>
                                        <th className="tableHead">Email</th>
                                        <th>
                                            <div style={{ width: "100px", textAlign: "center" }}>Phone</div>
                                        </th>
                                        <th>
                                            <div style={{ width: "100px", textAlign: "center" }}>Location</div>
                                        </th>

                                        <th width="50px" className="tableHead">
                                            User Since
                                        </th>

                                        <th className="tableHead">Profile Status</th>
                                        <th className="tableHead">#Edit Profile</th>
                                        <th className="tableHead">#Delete Freelancer</th>

                                        <th style={{ textAlign: "center", cursor: "pointer", textTransform: "capitalize", minWidth: "100px" }}>
                                            <div className="tableHead">
                                                #Hourly Project Applied
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <IoMdArrowDropup title="Sort Ascending" style={{ fontSize: "1.3em", color: sortField === "hourlyJobs" && sortOrder === "asc" ? "white" : "#ddd" }} onClick={() => handleSort("hourlyJobs", "asc")} />
                                                    <IoMdArrowDropdown title="Sort Descending" style={{ fontSize: "1.3em", color: sortField === "hourlyJobs" && sortOrder === "desc" ? "white" : "#ddd" }} onClick={() => handleSort("hourlyJobs", "desc")} />
                                                </div>
                                            </div>
                                        </th>

                                        <th style={{ textAlign: "center", cursor: "pointer", textTransform: "capitalize" }} className="tableheadingstyle18">
                                            <div className="tableHead">
                                                #Fixed Projects Applied
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <IoMdArrowDropup title="Sort Ascending" style={{ fontSize: "1.3em", color: sortField === "fixedJobs" && sortOrder === "asc" ? "white" : "#ddd" }} onClick={() => handleSort("fixedJobs", "asc")} />
                                                    <IoMdArrowDropdown title="Sort Descending" style={{ fontSize: "1.3em", color: sortField === "fixedJobs" && sortOrder === "desc" ? "white" : "#ddd" }} onClick={() => handleSort("fixedJobs", "desc")} />
                                                </div>
                                            </div>
                                        </th>

                                        <th style={{ textAlign: "center", cursor: "pointer", textTransform: "capitalize" }} className="tableheadingstyle18">
                                            <div style={{ width: "200px", display: "flex" }}>
                                                #Viewing Freelancer's Employer Contact
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <IoMdArrowDropup title="Sort Ascending" style={{ fontSize: "1.3em", color: sortField === "fixedJobs" && sortOrder === "asc" ? "white" : "#ddd" }} onClick={() => handleSort("viewingFreelancer", "asc")} />
                                                    <IoMdArrowDropdown title="Sort Descending" style={{ fontSize: "1.3em", color: sortField === "fixedJobs" && sortOrder === "desc" ? "white" : "#ddd" }} onClick={() => handleSort("viewingFreelancer", "desc")} />
                                                </div>
                                            </div>
                                        </th>

                                        <th style={{ textAlign: "center", cursor: "pointer", textTransform: "capitalize" }} className="tableheadingstyle18">
                                            <div className="tableHead">
                                                #Hourly/Fixed Rejected
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <IoMdArrowDropup title="Sort Ascending" style={{ fontSize: "1.3em", color: sortField === "jobsRejected" && sortOrder === "asc" ? "white" : "#ddd" }} onClick={() => handleSort("jobsRejected", "asc")} />
                                                    <IoMdArrowDropdown title="Sort Descending" style={{ fontSize: "1.3em", color: sortField === "jobsRejected" && sortOrder === "desc" ? "white" : "#ddd" }} onClick={() => handleSort("jobsRejected", "desc")} />
                                                </div>
                                            </div>
                                        </th>

                                        <th style={{ textAlign: "center", cursor: "pointer", textTransform: "capitalize" }} className="tableheadingstyle18">
                                            <div className="tableHead">
                                                #Hourly/Fixed Shortlisted
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <IoMdArrowDropup title="Sort Ascending" style={{ fontSize: "1.3em", color: sortField === "jobsShortlisted" && sortOrder === "asc" ? "white" : "#ddd" }} onClick={() => handleSort("jobsShortlisted", "asc")} />
                                                    <IoMdArrowDropdown title="Sort Descending" style={{ fontSize: "1.3em", color: sortField === "jobsShortlisted" && sortOrder === "desc" ? "white" : "#ddd" }} onClick={() => handleSort("jobsShortlisted", "desc")} />
                                                </div>
                                            </div>
                                        </th>

                                        <th style={{ textAlign: "center", cursor: "pointer", textTransform: "capitalize" }}>
                                            <div className="tableHead">
                                                #Hourly/Fixed Hired
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <IoMdArrowDropup title="Sort Ascending" style={{ fontSize: "1.3em", color: sortField === "jobsHired" && sortOrder === "asc" ? "white" : "#ddd" }} onClick={() => handleSort("jobsHired", "asc")} />
                                                    <IoMdArrowDropdown title="Sort Descending" style={{ fontSize: "1.3em", color: sortField === "jobsHired" && sortOrder === "desc" ? "white" : "#ddd" }} onClick={() => handleSort("jobsHired", "desc")} />
                                                </div>
                                            </div>
                                        </th>

                                        <th style={{ textAlign: "center", textTransform: "capitalize" }} className="tableheadingstyle25">
                                            <div className="tableHead">
                                                Balance #Connects
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <IoMdArrowDropup title="Sort Ascending" style={{ fontSize: "1.3em", color: sortField === "connectCredit" && sortOrder === "asc" ? "white" : "#ddd" }} onClick={() => handleSort("connectCredit", "asc")} />
                                                    <IoMdArrowDropdown title="Sort Descending" style={{ fontSize: "1.3em", color: sortField === "connectCredit" && sortOrder === "desc" ? "white" : "#ddd" }} onClick={() => handleSort("connectCredit", "desc")} />
                                                </div>
                                            </div>
                                        </th>
                                        <th style={{ textAlign: "center", textTransform: "capitalize" }} className="tableheadingstyle25">
                                            <div className="tableHead">
                                                #Connects Spent All
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <IoMdArrowDropup title="Sort Ascending" style={{ fontSize: "1.3em", color: sortField === "connectSpent" && sortOrder === "asc" ? "white" : "#ddd" }} onClick={() => handleSort("connectSpent", "asc")} />
                                                    <IoMdArrowDropdown title="Sort Descending" style={{ fontSize: "1.3em", color: sortField === "connectSpent" && sortOrder === "desc" ? "white" : "#ddd" }} onClick={() => handleSort("connectSpent", "desc")} />
                                                </div>
                                            </div>
                                        </th>
                                        <th className="tableHead">Address</th>
                                        <th className="tableHead">Identity</th>
                                        <th className="tableHead">Billing Statement</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading2 ? (
                                        <>
                                            <Grid item xs={12} md={12} container justifyContent="center" alignItems="center" style={{ paddingLeft: "5%" }}>
                                                <div className=" mx-auto" style={{ marginBottom: "20px", marginTop: "20px", justifyContent: "center", justifyItem: "center", textAlign: "center" }}>
                                                    <ScaleLoader color={"#4B92C8"} loading={true} size={15} aria-label="Loading Spinner" data-testid="loader" />
                                                </div>
                                            </Grid>
                                        </>
                                    ) : (
                                        <>
                                            {candidateData.length != 0 ? (
                                                <>
                                                    {candidateData.map((data, index) => (
                                                        <tr key={index}>
                                                            <td style={{ fontSize: "14px", cursor: "pointer", minWidth: "150px", textDecoration: "underline", color: "#4B92C8" }} onClick={() => handleLogin(data._id)} className="tableheadingstyle1">
                                                                <Tooltip title={<Typography sx={{ fontSize: "16px" }}>{data.name}</Typography>} arrow disableHoverListener={data.name.length <= 12}>
                                                                    <p style={{ marginBottom: "0px", cursor: "pointer" }}>{data.name && <> {data.name.length > 12 ? data.name.slice(0, 12) + "..." : data.name} </>}</p>
                                                                </Tooltip>
                                                            </td>
                                                            <td style={{ fontSize: "14px" }} className="tableheadingstyle10">
                                                                {data?.resume?.PersonalDetails?.minimumRate ? "$" + data?.resume?.PersonalDetails?.minimumRate + " per hour" : "Not Available"}
                                                            </td>

                                                            <td style={{ fontSize: "14px", minWidth: "150px" }} className="tableheadingstyle">
                                                                <Tooltip title={<Typography sx={{ fontSize: "16px" }}>{data.email}</Typography>} arrow disableHoverListener={data.email.length <= 16}>
                                                                    <p style={{ marginBottom: "0px", cursor: "pointer" }}>{data.email && <> {data.email.length > 16 ? data.email.slice(0, 16) + "..." : data.email} </>}</p>
                                                                </Tooltip>
                                                            </td>

                                                            <td style={{ fontSize: "14px" }} className="tableheadingstyle10">
                                                                {data.phone ? ("+"+data?.resume?.PersonalDetails?.countryCode+ " " + data.phone) : "Not Available"}
                                                            </td>
                                                            <td style={{ fontSize: "14px" }} className="tableheadingstyle10">
                                                                {data.phone ? data.city : "Not Available"}
                                                            </td>
                                                            <td style={{ fontSize: "14px", minWidth: "140px" }} className="tableheadingstyle9">
                                                                {data.dataAdded.slice(8, 10) + " " + MONTH_DETAILS[parseInt(data.dataAdded.slice(5, 7), 10)] + "' " + data.dataAdded.slice(2, 4)}
                                                            </td>
                                                            <td style={{ textAlign: "center" }} className="tableheadingstyle3">
                                                                <div className="dropdown3 ml-4" style={{ borderRadius: "5px", border: "solid 1px #4B92C8", marginBottom: "-10px" }}>
                                                                    <button className="btn dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false" onClick={() => idSelector(data._id)} style={{ fontSize: "15px", width: "100px" }}>
                                                                        {data.active == false ? "De-active" : "Active"}
                                                                    </button>
                                                                    <ul className="dropdown-menu dropdown-menu-light px-2 custom-dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                                                        {data.active == false ? (
                                                                            <li>
                                                                                <a className="dropdown-item border text-center py-2" style={{ backgroundColor: "#4B92C8", color: "white", borderRadius: "5px", cursor: "pointer", fontSize: "15px" }} onClick={ActiveUser}>
                                                                                    Active
                                                                                </a>
                                                                            </li>
                                                                        ) : (
                                                                            <li>
                                                                                <a className="dropdown-item border text-center py-2" style={{ backgroundColor: "#4B92C8", color: "white", borderRadius: "5px", cursor: "pointer", fontSize: "15px" }} onClick={DeActiveUser}>
                                                                                    De-active
                                                                                </a>
                                                                            </li>
                                                                        )}
                                                                    </ul>
                                                                </div>
                                                            </td>
                                                            <td style={{ fontSize: "14px", textAlign: "center" }}>
                                                                <LuClipboardEdit className="docIcon" style={{ fontSize: "21px", color: "#4B92C8" }} onClick={() => fetchData(data._id)} data-bs-toggle="modal" data-bs-target="#exampleModal" />
                                                            </td>
                                                            <td style={{ fontSize: "14px", textAlign: "center" }} className="tableheadingstyle deletehovering">
                                                                <RiDeleteBinLine className="docIcon" style={{ fontSize: "23px", color: "#646464" }} onClick={() => handleDeleteConfirmation(data._id)} />
                                                            </td>
                                                            <td style={{ fontSize: "14px", textAlign: "center" }} className="tableheadingstyle18">
                                                                {countHourlyJobs(data.appliedJobs)}
                                                            </td>
                                                            <td style={{ fontSize: "14px", textAlign: "center" }} className="tableheadingstyle18">
                                                                {countFixedCost(data.appliedJobs)}
                                                            </td>
                                                            <td style={{ fontSize: "14px", textAlign: "center" }} className="tableheadingstyle18">
                                                                {data.connectSpentJobSeeker ? data.connectSpentJobSeeker : 0}
                                                            </td>
                                                            <td style={{ fontSize: "14px", textAlign: "center" }} className="tableheadingstyle18">
                                                                {" "}
                                                                {countRejectedJobs(data.appliedJobs)}
                                                            </td>
                                                            <td style={{ fontSize: "14px", textAlign: "center" }} className="tableheadingstyle18">
                                                                {countShortlistedJobs(data.appliedJobs)}
                                                            </td>
                                                            <td style={{ fontSize: "14px", textAlign: "center" }} className="tableheadingstyle17">
                                                                {countHiredJobs(data.appliedJobs)}
                                                            </td>
                                                            <td>
                                                                <div className="tableheadingstyle25 editableconnectcredit" style={{ fontSize: "14px", textAlign: "center" }} contentEditable="true" onKeyDown={(e) => handleKeyDown3(e, data._id)} onBlur={(e) => handleBlur(e, data._id)}>
                                                                    {data.connectCredit}
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div style={{ fontSize: "14px", textAlign: "center" }} className="tableheadingstyle25 editableconnectcredit" contentEditable="true" onKeyDown={(e) => handleKeyDown4(e, data._id)} onBlur={(e) => handleBlur2(e, data._id)}>
                                                                    {data.connectSpent}
                                                                </div>
                                                            </td>

                                                            <td style={{ fontSize: "14px", textAlign: "center", minWidth: "300px" }} className="tableheadingstyle17">
                                                                {data?.resume?.AddressDetail && (
                                                                    <Tooltip
                                                                        title={
                                                                            <Typography sx={{ fontSize: "16px" }}>
                                                                                {(() => {
                                                                                    const addressParts = [data?.resume?.AddressDetail?.address, data?.resume?.AddressDetail?.address2, data?.resume?.AddressDetail?.city, data?.resume?.AddressDetail?.state, data?.resume?.AddressDetail?.code].filter((part) => part && part.trim() !== "");

                                                                                    return addressParts.length > 0 ? addressParts.map((part, index) => (index === addressParts.length - 1 ? part : `${part},`)).join(" ") : "Not Available";
                                                                                })()}
                                                                            </Typography>
                                                                        }
                                                                        arrow
                                                                    >
                                                                        <span>
                                                                            {(() => {
                                                                                const addressParts = [data?.resume?.AddressDetail?.address, data?.resume?.AddressDetail?.address2, data?.resume?.AddressDetail?.city, data?.resume?.AddressDetail?.state, data?.resume?.AddressDetail?.code].filter((part) => part && part.trim() !== "");

                                                                                const fullAddress = addressParts.map((part, index) => (index === addressParts.length - 1 ? part : `${part},`)).join(" ");

                                                                                return fullAddress.length > 35 ? `${fullAddress.slice(0, 35)}...` : fullAddress || "Not Available";
                                                                            })()}
                                                                        </span>
                                                                    </Tooltip>
                                                                )}

                                                                {!data?.resume?.AddressDetail && <span>Not Available</span>}
                                                            </td>

                                                            <td style={{ fontSize: "14px", textAlign: "center", minWidth: "150px" }} className="tableheadingstyle17">
                                                                {data?.resume?.IdentityDetails?.frontPhoto?.url && (
                                                                    <a target="_blank" style={{ color: "#4b92c8", textDecoration: "underline" }} href={data?.resume?.IdentityDetails?.frontPhoto?.url}>
                                                                        Front IMG
                                                                    </a>
                                                                )}
                                                                &nbsp;&nbsp;
                                                                {/* {data?.resume?.IdentityDetails?.frontPhoto?.url && <br />} */}
                                                                {data?.resume?.IdentityDetails?.backPhoto?.url && (
                                                                    <a target="_blank" style={{ color: "#4b92c8", textDecoration: "underline" }} href={data?.resume?.IdentityDetails?.backPhoto?.url}>
                                                                        Back IMG
                                                                    </a>
                                                                )}
                                                                {!data?.resume?.IdentityDetails?.backPhoto?.url && !data?.resume?.IdentityDetails?.backPhoto?.url && <span>Not Available</span>}
                                                            </td>
                                                            <td style={{ fontSize: "14px", textAlign: "center", minWidth: "150px" }} className="tableheadingstyle17">
                                                                {data?.resume?.DocDetails?.length > 0 && data?.resume?.DocDetails[0]?.url ? (
                                                                    <a target="_blank" style={{ color: "#4b92c8", textDecoration: "underline" }} href={data.resume.DocDetails[0].url}>
                                                                        Document
                                                                    </a>
                                                                ) : (
                                                                    <span>Not Available</span>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </>
                                            ) : (
                                                <>
                                                    <p style={{ padding: "15px  20px", minWidth: "200px", paddingBottom: "10", color: "red" }}>No data found!</p>
                                                </>
                                            )}
                                        </>
                                    )}
                                </tbody>
                            </table>
                        </div>

                        <div className="pagination" style={{ width: "100%", maxWidth: "1200px", marginTop: "-40px" }}>
                            <Grid item xs={12} md={12} container justifyContent="end" alignItems="end" sx={{ marginBottom: "1%", paddingRight: "5%" }}>
                                {totalPages > 1 ? (
                                    <></>
                                ) : (
                                    <>
                                        <div></div>
                                    </>
                                )}
                            </Grid>
                            {totalPages > 1 ? (
                                <>
                                    <div style={{ textAlign: "center", maxWidth: "1200px", marginInline: "auto" }}>
                                        <PaginationWithLastButton totalPages={totalPages} currentPage={currentPage} onPageChange={setCurrentPage} />
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>

                        <div className="table-box table-responsive border borderremove px-0">
                            <table className="applications table" style={{ marginBottom: "0px" }}>
                                <thead>
                                    <tr>
                                        <th style={{ padding: "2rem 2.9rem", minWidth: "170px", textAlign: "center" }}>Total Freelancers</th>
                                        <th style={{ padding: "2rem 2.9rem", minWidth: "170px", textAlign: "center" }}>Total Employers</th>
                                        <th style={{ padding: "2rem 2.9rem", minWidth: "170px", textAlign: "center" }}>Total Hourly Projects</th>
                                        <th style={{ padding: "2rem 2.9rem", minWidth: "170px", textAlign: "center" }}>Total Fixed Cost Projects</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ padding: "2rem 3rem 0rem 3rem" }}>
                                            <div style={{ minWidth: "170px", textAlign: "center", fontSize: "14px" }}>{totalData?.totalFreelancers}</div>
                                        </td>

                                        <td style={{ padding: "2rem 3rem 0rem 3rem" }}>
                                            <div style={{ minWidth: "170px", textAlign: "center", fontSize: "14px" }}>{totalData?.totalEmployers}</div>
                                        </td>
                                        <td style={{ padding: "2rem 3rem 0rem 3rem" }}>
                                            <div style={{ minWidth: "170px", textAlign: "center", fontSize: "14px" }}>{totalData?.totalHourlyProject}</div>
                                        </td>
                                        <td style={{ padding: "2rem 3rem 0rem 3rem" }}>
                                            <div style={{ minWidth: "170px", textAlign: "center", fontSize: "14px" }}>{totalData?.totalFixedCostProject}</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </>
                )}
            </div>

            {/* EDIT PROFILE MODEL */}

            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content borderremove123" ref={borderRemoveRef} style={{ overflowY: "auto", borderRadius: "2px", height: "auto" }}>
                        <div className="modal-body">
                            <div style={{ textAlign: "end", paddingRight: "30px" }}>
                                <button style={{ position: "fixed" }} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            {/*EDIT APPLICATION CODE START HERE*/}
                            <div className="container">
                                <p className="text-center mx-auto" style={{ margin: "40px 0px", fontSize: "20px", fontWeight: "500", color: "#5F5F5F" }}>
                                    Edit Profile
                                </p>
                                <div className="section mx-auto" style={{ width: "90%" }}>
                                    <form>
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Name</label>
                                            <input type="text" className="form-control mt-1" style={{ fontSize: "15px", paddingTop: "18px", paddingBottom: "18px" }} placeholder="John Deo" value={candidate && candidate.name} onChange={handleUserChange} id="name" name="name" />
                                        </div>

                                        <div className="form-group ">
                                            <label htmlFor="exampleInputEmail1 mt-2">Phone</label>
                                            <input type="text" className="form-control mt-1" style={{ fontSize: "15px", paddingTop: "18px", paddingBottom: "18px" }} placeholder="Enter Phone No." onChange={handleUserChange} id="phone" name="phone" value={candidate && candidate.phone} />
                                        </div>
                                        <div className="form-group ">
                                            <label htmlFor="exampleInputEmail1 mt-2">City</label>
                                            <input type="text" className="form-control mt-1" style={{ fontSize: "15px", paddingTop: "18px", paddingBottom: "18px" }} placeholder="Enter City Name" onChange={handleUserChange} id="city" name="city" value={candidate && candidate.city} />
                                        </div>

                                        <label htmlFor="exampleInputEmail1 mt-2">Availability</label>
                                        <select aria-label="Default select example" className="form-select mt-1" style={{ fontSize: "15px", paddingTop: "8px", paddingBottom: "6px" }} onChange={handleUserChange} id="availability" name="availability" value={candidate && candidate.availability}>
                                            <option selected>Select availability</option>
                                            <option value="Immediate">Immediate</option>
                                            <option value="Within 1 Week">Within 1 Week</option>
                                            <option value="Within 2 Weeks">Within 2 Weeks</option>
                                            <option value="Within 3 Weeks">Within 3 Weeks</option>
                                            <option value="Within 4 Weeks">Within 4 Weeks</option>
                                            <option value="Within 5 Weeks">Within 5 Weeks</option>
                                            <option value="Within 6 Weeks">Within 6 Weeks</option>
                                            <option value="Within 7 Weeks">Within 7 Weeks</option>
                                            <option value="Within 8 Weeks">Within 8 Weeks</option>
                                        </select>
                                    </form>
                                    <div className="d-flex gap-4">
                                        <button onClick={handleUpdate} className="resume-upload  my-5">
                                            {loading ? <Spinner /> : <span>Update</span>}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/*EDIT APPLICATION CODE END HERE*/}
                        </div>
                    </div>
                </div>
            </div>
            <Popup open={showModal} modal closeOnDocumentClick={false}>
                <div className="overlay" style={{ background: "rgba(0, 0, 0, 0.4)" }}>
                    <div className="popup-containernew">
                        <div className="success-message mx-3" style={{ marginBottom: "10px" }}>
                            Are you sure you want to delete? This action cannot be undone.
                        </div>
                        <div style={{ display: "flex", alignItems: "center", alignSelf: "center", gap: "10px", marginBottom: "15px" }}>
                            <button className="employerbuttonbacktodash" onClick={handleCancelDelete}>
                                Cancel
                            </button>
                            <button className="employerbuttontobuyinformation" style={{ backgroundColor: "#ED5E68" }} onClick={handleConfirmDelete}>
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </Popup>
            <ToastContainer position="top-center" autoClose={10000} />
        </>
    );
}

export default AdminCandidates;
