import React, { useEffect, useState } from "react";
import "./css/applicationdetail.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ApplicationsAll from "./ApplicationsAll";
import Filters from "./Filters";
import { FaChevronDown } from "react-icons/fa";
import { FaFilter } from "react-icons/fa";
import axios from "axios";
import { logEvent } from "../../utils/Analytics";
import { useSelector } from "react-redux";
const ApplicationDetails = () => {
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: "none",
            borderRadius: "3px",
            boxShadow: "none",
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            display: "none",
            borderLeft: "none",
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            display: "none",
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            paddingRight: 0, // Adjust padding to remove space between text and dropdown icon
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "#ECFFFF" : "white", // Change hover color here
            color: state.isFocused ? "#3498DB" : "black", // Change hover color here
            "&:hover": {
                backgroundColor: "#ECFFFF", // Change hover color here
                color: "#3498DB",
            },
        }),
    };


    const [state, setState] = useState(null);
    const location = useLocation();
    const params2 = new URLSearchParams(location.search);
    const encodedData = params2.get("data");
    const state2 = encodedData
        ? JSON.parse(decodeURIComponent(encodedData))
        : null;

    const { user } = useSelector((state) => state.user)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `/api/employer/getJobInformationInState?id=${state2}`
                );
                //console.log(response, "response data");
                setState(response.data);
            } catch (error) {
                console.error("Error fetching job data:", error);
            }
        };

        if (state2) {
            fetchData();
        }
    }, [state2]);

    // const { state } = useLocation();
    const navigate = useNavigate();
    const pathname = useLocation();
    const params = useParams();
    const [counts, setCounts] = useState({});
    const [filterTrigger, setFilterTrigger] = useState(0);
    const [showMenu, setShowMenu] = useState(false);
    const [showfilter, setShowFilter] = useState(false);
    const [searchApplicant, setSearchApplicant] = useState("");
    const [chats, setChats] = useState(0);
    const [searchTrigger, setSearchTrigger] = useState(false);
    const handleChange = (selectedOption) => {
        setSearchApplicant(selectedOption);
    };

    const initial = {
        id: state2,
        applicants_location: [],
        skills: [],
        academic_background: [],
        graduation_year: [],
    };

    const [filterInput, setFilterInput] = useState(initial);

    const getCount = () => {
        const type = params?.type;
        if (type == "All") {
            return ["Application received", counts?.ApplicationReceivedCount];
        }
        if (type == "Shortlisted") {
            return ["Shortlisted", counts?.ShortlistedCount];
        }

        if (type == "Hired") {
            return ["Hired", counts?.HiredCount];
        }

        return ["Not Interested", counts?.NotInterestedCount];
    };

    const handleClick = (to) => {
        navigate(`${to}?data=${encodeURIComponent(encodedData)}`);
        setShowMenu(false);
    };

    useEffect(() => {
        async function getChatCounts() {
            const response = await axios.get(`/chats/messagecount/${state2}`, {
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token"),
                },
            });

            const data = await response.data;
            setChats(data?.chats);
        }

        getChatCounts();
        window.scrollTo(0, 0);
    }, []);
    const handleSearch = () => {
        setFilterInput((prevFilterInput) => ({
            ...prevFilterInput,
            firstName: searchApplicant,
            lastName: searchApplicant,
            email: searchApplicant,
        }));
        setSearchTrigger((prevTrigger) => !prevTrigger);
    };

    const handleAddEvent = (str) => {
        logEvent("Button", `Clicked ${str} button`, user ? user.role : "Guest");
    }

    return (
        <div className="application-details-list">
            <nav>
                <div>
                    <a href="/employerApplication/Internship">Dashboard</a> {`>`}{" "}
                    Applications received
                </div>
                <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-md-between gap-3 gap-md-0">
                    <span className="profileName" >
                        Applications for {state?.profile} {state?.opportunityType}
                    </span>
                    {/*  <div style={{ position: "relative" }}>
            <div
              style={{
                position: "absolute",
                top: "0",
                bottom: "0",
                padding: "0px 8px",
                fontSize: "16px",
                color: "#333",
                display: "flex",
                alignItems: "center",
              }}
            >
              <CiSearch />
            </div>
            <input
              className="px-5"
              value={searchApplicant}
              onChange={(e) => setSearchApplicant(e.target.value)}
              type="text"
              placeholder="Search applicants by name"
            />
          </div>
         
      */}

                    <div className="AdminSearchBoxEmployerReceived">
                        <input
                            style={{ marginRight: "0px", width: "210px" }}
                            className="AdminSearchInputEmployerReceived"
                            type="text"
                            placeholder="Search by name, email"
                            value={searchApplicant}
                            onChange={(e) => setSearchApplicant(e.target.value)}
                        />
                        <button
                            className="AdminSearchButtonEmployerReceived"
                            onClick={handleSearch}
                        >
                            Search
                        </button>
                    </div>
                </div>
            </nav>

            <div className="section">
                <div className="tabs">
                    {/*     <Select
        value={searchApplicant}
        onChange={handleChange}
        options={options}
        placeholder="Search applicants by name"
        className="mx-3 mb-3"
        styles={customStyles} // Apply custom styles
    />  */}
                    <div
                        onClick={() => handleClick("/employerappreceived/All")}
                        className={
                            pathname.pathname == "/employerappreceived/All"
                                ? "side-items active"
                                : "side-items"
                        }
                    >
                        Applications received{" "}
                        <span>{counts?.ApplicationReceivedCount}</span>
                    </div>
                    <div
                        onClick={() => { handleClick("/employerappreceived/Shortlisted"), handleAddEvent("Shortlisted") }}
                        className={
                            pathname.pathname == "/employerappreceived/Shortlisted"
                                ? "side-items active"
                                : "side-items"
                        }
                    >
                        Shortlisted<span>{counts?.ShortlistedCount}</span>
                    </div>
                    <div
                        onClick={() => { handleClick("/employerappreceived/Hired"), handleAddEvent("Hired") }}
                        className={
                            pathname.pathname == "/employerappreceived/Hired"
                                ? "side-items active"
                                : "side-items"
                        }
                    >
                        Hired<span>{counts?.HiredCount}</span>
                    </div>
                    <div
                        onClick={() => { handleClick("/employerappreceived/Ninterested"), handleAddEvent("Not interested") }}
                        className={
                            pathname.pathname == "/employerappreceived/Ninterested"
                                ? "side-items active"
                                : "side-items"
                        }
                    >
                        Not interested<span>{counts?.NotInterestedCount}</span>
                    </div>
                    <div
                        style={{ background: "#ddd", height: "1px", margin: "0px 12px" }}
                    ></div>
                    {/* <div
            onClick={() => navigate("/employerappreceived/Assignments", { state: state })}
            className={pathname.pathname == "/employerappreceived/Assignments" ? "side-items active" : "side-items"}
          >
            Assignments<span>{counts?.AssignmentCount}</span>
          </div>
          <div
            onClick={() => navigate("/employerappreceived/Interviews", { state: state })}
            className={pathname.pathname == "/employerappreceived/Interviews" ? "side-items active" : "side-items"}
          >
            Interviews<span>{counts?.InterviewsCount}</span>
          </div> */}
                    {/*  <a
            target="__blank"
            href={`/messageEmployer/${state._id}`}
            className={pathname.pathname == "/messageEmployer" ? "side-items active" : "side-items"}
          >
            Chat messages<span>{chats} NEW</span>
        </a>*/}
                </div>

                <div className="lists">
                    <ApplicationsAll
                        setCounts={setCounts}
                        counts={counts}
                        filterInput={filterInput}
                        setFilterInput={setFilterInput}
                        initial={initial}
                        filterTrigger={filterTrigger}
                        setFilterTrigger={setFilterTrigger}
                        searchApplicant={searchApplicant}
                        setSearchApplicant={setSearchApplicant}
                        searchTrigger={searchTrigger}
                        opportunityType={state?.opportunityType}
                    />
                </div>
                <div className="filters">
                    <Filters
                        filterInput={filterInput}
                        setFilterInput={setFilterInput}
                        setFilterTrigger={setFilterTrigger}
                        initial={initial}
                        setShowFilter={setShowFilter}
                    />
                </div>
            </div>

            <div className="small-window">
                <div
                    onClick={() => setShowMenu(false)}
                    className={showMenu ? "overlayH" : "no-overlayH"}
                ></div>
                <div className={showMenu ? "menu" : "no-menu"}>
                    <div
                        onClick={() => { handleClick("/employerappreceived/All"), handleAddEvent("Applications received") }}
                        className="side-items"
                    >
                        Applications received{" "}
                        <span>{counts?.ApplicationReceivedCount}</span>
                    </div>
                    <div
                        onClick={() => { handleClick("/employerappreceived/Shortlisted", handleAddEvent("Shortlisted")) }}
                        className="side-items"
                    >
                        Shortlisted <span>{counts?.ShortlistedCount}</span>
                    </div>
                    <div
                        onClick={() => { handleClick("/employerappreceived/Hired"), handleAddEvent("Hired") }}
                        className="side-items"
                    >
                        Hired<span> {counts?.HiredCount}</span>
                    </div>
                    <div
                        onClick={() => { handleClick("/employerappreceived/Ninterested"), handleAddEvent("Not interested") }}
                        className="side-items"
                    >
                        Not interested <span>{counts?.NotInterestedCount}</span>
                    </div>
                </div>

                <div onClick={() => setShowFilter(false)} className={showfilter ? "overlay" : "no-overlay"} ></div>
                
                <div className={showfilter ? "filter-main" : "no-filter-main"}>
                    <div className="filters">
                        <Filters
                            filterInput={filterInput}
                            setFilterInput={setFilterInput}
                            setFilterTrigger={setFilterTrigger}
                            initial={initial}
                            setShowFilter={setShowFilter}
                        />
                    </div>
                </div>

                <div className="bottom-bar">
                    <div
                        onClick={() => setShowMenu(!showMenu)}
                        className={!showMenu ? "items" : "items items-open"}
                    >
                        <span className="item-title">
                            {getCount()[0]}{" "}
                            <span className="item-count">{getCount()[1]}</span>
                        </span>
                        <FaChevronDown
                            style={{
                                transform: `${showMenu ? "rotate(180deg)" : "rotate(0deg)"}`,
                                transition: "all 0.3s",
                            }}
                        />
                    </div>
                    <div
                        onClick={() => setShowFilter(true)}
                        className={!showMenu ? "items" : "items-close"}
                    >
                        <FaFilter className="item-title" /> Filters
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ApplicationDetails;
