import React, { useState, useEffect } from "react";
import "./css/EmployeeSignup.scss";
import SignupImage1 from "../../assets/images/signupEmployee1.png";
import SignupImage2 from "../../assets/images/signupEmployee2.png";
import Spinner from "../Layout/Spinner";
import axios from "axios";
import Recruiter from "../../assets/images/emp.png";
import GoogleIcon from "../../assets/images/icons/google.png";
import "../Student/css/StudentSignup.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import OAuth2Login from "react-simple-oauth2-login";
import { useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import arrowup from "../../assets/images/Arrowup.jpg";
import { envelope, lock, mobile,userIcon,eyeClosed,eyeOpen } from "../../assets";
import CountryCodeDropDown from "./CountryCodeDropDown";
import { logEvent } from "../../utils/Analytics";
function EmployerSignup() {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo(0, 0);
    }, []);
  const [hideShowPassword,sethideShowPassword] = useState(false)

    const navigate = useNavigate();
    const [selectedCountry, setSelectedCountry] = useState("");
    const [user, setUser] = useState({ role: "Employer",countryCode:selectedCountry });
    const [loading, setLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const dispatch = useDispatch();
    
    const validate = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        let isValid = true;
        if (!values.name) {
            errors.name = "Name is required";
            isValid = false;
        }

        if (!values.email) {
            errors.email = "Email is required";
            isValid = false;
        } else if (!regex.test(values.email)) {
            errors.email = "This is not a valid email format";
            isValid = false;
        }

        if (!values.password) {
            errors.password = "password is required";
            isValid = false;
        } else if (values.password.length < 6) {        
            errors.password = "Password must be at least six characters long";
            isValid = false;
        }
        if(!values.countryCode){
            errors.countryCode = "Country code is required";
            isValid = false;
        }
        if (!values.phone) {
            errors.phone = "contact number is required";
            isValid = false;
        } else if (values.phone.length < 7 || values.phone.length > 16) {
            errors.phone = "Please confirm your phone number";
            isValid = false;
        }

        setFormErrors(errors);
        return isValid;
    };

    const handleUserChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const handleCountrySelect = (countryCode) => {
        console.log(countryCode);
        if(countryCode !== null){
            setUser({...user,countryCode:countryCode.phone})
        }else{
            setUser({...user,countryCode:""})

        }
    }
    console.log(user);
    const handleAddEvent = () => {
            logEvent("Button", "Clicked login button", user ? user.role : "Guest");
        };
    const openAccordian = (event) => {
        console.log(event.target.parentNode);
        let body = event.target.parentNode.querySelector(".js-accordion-body");
        if (body.classList.contains("show")) {
            body.classList.remove("show");
        } else {
            body.classList.add("show");
        }
    };

    const signup = async (event) => {
        
        event.preventDefault();
        setLoading(true);
        const isValid = validate(user);
        if (isValid) {  
            try {
                const { data } = await axios.post("/api/user/otp", user);
                console.log(data);
                if (data.status === 404) {
                    toast.error("This email is already used by another user", {
                        style: {
                            padding: "18px",
                        },
                    });
                    return; // Exit function if email is already in use
                }

                if (data.status === false) {
                    toast.error(data.message || "Failed to send OTP. Please try again.", {
                        style: {
                            padding: "18px",
                        },
                    });
                    return; // Exit function if OTP sending failed
                }
                logEvent("Button", "Clicked signup button", user ? user.role : "Guest");
                navigate("/otpverification", { state: user });
            } catch (error) {
                console.log(error.response.data);
                setLoading(false);
                toast.error(error.response?.data?.message || "An error occurred. Please try again.", {
                    style: {
                        padding: "18px",
                    },
                });
            }
        } else {
            setLoading(false);
            const error = Object.values(formErrors);
            toast.error(error[0] ? error[0] : "All fields are required", {
                style: {
                    padding: "18px",
                },
            });
        }
    };

    useEffect(() => {
        if (localStorage.getItem("token")) {
            const userType = localStorage.getItem("userType");

            if (userType === "User" || userType === "Employer") {
                navigate("/");
            }
        }
    }, []);

    const handleSuccess = (token) => {
        console.log("Login successful:", token);
    };

    const handleFailure = (error) => {
        console.error("Login failed:", error);
    };

    const handleGoogleLoginSuccessEmployer = async (credentialResponse) => {
        try {
            const decoded = jwtDecode(credentialResponse.credential);

            console.log("Decoded token:", decoded);

            const response = await axios.post("/api/employer/googleLoginEmployer", {
                tokenId: credentialResponse.credential,
            });

            console.log("Server response:", response.data);

            const { token, user } = response.data;

            if (token) {
                localStorage.setItem("token", token);

                if (user.role === "Employer") {
                    try {
                        const data = await fetch("/api/user/me", {
                            method: "GET",
                            headers: {
                                "Content-Type": "application/json",
                                token: localStorage.getItem("token"),
                            },
                        });
                        const json = await data.json();
                        const logData = json.user;
                        dispatch({ type: "LOAD_USER_SUCCESS", payload: logData });
                        console.log("Loaded user data:", logData);
                    } catch (error) {
                        console.error("Error loading user data:", error);
                    }

                    localStorage.setItem("userType", "Employer");
                }
                navigate("/");
            } else {
                console.log("Login failed:", response.data.message);
            }
        } catch (error) {
            toast.error(error.response ? error.response.data.message : error.message);

            console.error("Error during Google login:", error);
        }
    };

    const handleGoogleLoginFailure = (error) => {
        console.error("Google login failed:", error);
    };

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === "Enter") {
                const myButton = document.querySelector(".btnsign");
                if (myButton) {
                    myButton.click();
                } else {
                    console.error("Button not found");
                }
            }
        };
        // Attach the event listener to the document
        document.addEventListener("keydown", handleKeyPress);
        // Clean up the event listener when the component is unmounted
        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, []);
    console.log(selectedCountry);
    
    return (
        <>
            {/* <div className="signup-container">
                <div className="student-signup">
                    <div className="content">
                        <img src={SignupImage1} alt="signup" />
                        <img src={SignupImage2} alt="signup" />
                    </div>
                    <form onSubmit={signup} className="form-box">
                        <div className="form">
                            <h2 className="title">Sign Up</h2>
                            <div className="input-box">
                                <label htmlFor="email">Email</label>
                                <input type="email" name="email" onChange={handleUserChange} id="email" />
                            </div>
                            <div className="input-box">
                                <label htmlFor="password">Password</label>
                                <input type="password" name="password" onChange={handleUserChange} id="password" />
                            </div>
                            <div className="name-box">
                                <div className="input-box">
                                    <label htmlFor="firstname">Firstname</label>
                                    <input type="text" name='firstname' onChange={handleUserChange} />
                                </div>
                                <div className="input-box">
                                    <label htmlFor="lastname">Lastname</label>
                                    <input type="text" name='lastname' onChange={handleUserChange} />
                                </div>
                            </div>
                            <div className="input-box">
                                <label htmlFor="lastname">Mobile Number</label>
                                <div style={{ display: "grid", gridTemplateColumns: "20% 78%", gap: "1rem" }}>
                                    <input type="text" name='countryPhoneCode' onChange={handleUserChange} />
                                    <input type="text" name="phone" onChange={handleUserChange} id="phone" />
                                </div>
                            </div>
                            <p className="label">By Signing up, you agree to <a href="/">Terms & Conditions.</a></p>
                            <button className="btn btn-primary" style={{ width: "100%" }}>
                                {loading ? <Spinner /> : <span style={{ fontSize: "1.6rem" }}>Signup</span>}
                            </button>
                            <a href='/' className="text login-btn">Already Registered! Login?</a>
                        </div>
                    </form>
                </div>
            </div> */}
            <div className="signup-container container-fluid py-4" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                <div className="studentsignup my-3 container" >
                    <div className="row mx-auto" >
                        <div className="hideMob col-lg-7 col-md-6 col-sm-12 mx-auto mb-5" >
                            <div style={{ justifyContent: "center" }}>
                                <h3 className="title mx-3">
                                    <b>Streamline your hiring process to quickly onboard skilled freelancers</b>

                                    <p style={{ fontSize: "16px", marginTop: "-8px" }}>
                                        <b>Post a project now</b>
                                    </p>
                                </h3>
                                <p className="para12345 mx-3">"Join our exclusive employer portal and unlock your organization's full potential. Effortlessly discover top talent with our intuitive platform, making project posting, management, and tracking seamless. Enhance your recruitment strategy and connect with the brightest minds ready to drive your company's success. Your future workforce starts here."</p>
                        
                                <div className="text-center imagesection">
                                    <img src={Recruiter} alt="recruiter" style={{ marginTop: "30px" }} />
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-5 col-md-6 col-sm-12 my-5 mx-auto studentform">
                            <form className="form-box">
                                <div className="form" style={{ height: "auto" }}>
                                    <h2 className="title text-center" style={{ marginTop: "-40px" }}>
                                        Sign Up
                                    </h2>
                                    <TextField
                                        type="text"
                                        label="Name"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        name="name"
                                        onChange={handleUserChange}
                                        id="name"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <img src={userIcon} className="icons"></img>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />

                                    <TextField
                                        type="email"
                                        label="Email"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        name="email"
                                        onChange={handleUserChange}
                                        id="email"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <img src={envelope} className="icons"></img>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />

                                    <TextField
                                        type={hideShowPassword ? "text" : "password"}

                                        label="Password"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        name="password"
                                        onChange={handleUserChange}
                                        id="password"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {hideShowPassword ? (<img src={eyeOpen} className="icons" style={{cursor:"pointer"}} alt="password" onClick={()=> sethideShowPassword(!hideShowPassword)}/>) : (<img src={eyeClosed} className="icons" alt="password" style={{cursor:"pointer"}} onClick={()=> sethideShowPassword(!hideShowPassword)}/>) }
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <div className="countryMobileWrapper" >
                                        <div className="countryWrapper">
                                            <CountryCodeDropDown setSelectedCountry={handleCountrySelect}/>
                                        </div>

                                        <div className="mobileWrapper">
                                            <TextField
                                                type="number"
                                                label="Mobile Number"
                                                variant="outlined"
                                                fullWidth
                                                margin="normal"
                                                name="phone"
                                                onChange={handleUserChange}
                                                id="phone"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <img src={mobile} className="icons"></img>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                    </div>
                                    
                                    <button
                                        className="btn btnsign"
                                        style={{
                                            width: "100%",
                                            marginTop: "10px",
                                            paddingTop: "16px",
                                            paddingBottom: "16px",
                                        }}
                                        onClick={signup}
                                    >
                                        {loading ? <Spinner /> : <span style={{ fontSize: "1.6rem", fontWeight: "800" }}>Sign Up</span>}
                                    </button>
                                    <p className="text-center pt-4" style={{ fontSize: "13px", marginTop: "12px" }}>
                                        Already have an Account?{" "}
                                        <a href="/login" className="font-weight-bold" onClick={handleAddEvent} style={{ fontSize: "13px", marginLeft: "0px" }}>
                                            Log In
                                        </a>
                                    </p>
                                    
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
           
      
            <ToastContainer position="top-center" autoClose={10000} />
        </>
    );
}

export default EmployerSignup;
