import React, { useMemo } from 'react';

const ApplyButton = ({ data, user, profiledetail, connectCredit, setShowModal }) => {
    // Move URL generation logic to useMemo to prevent recalculation on every render
    const generateUrl = useMemo(() => {
        const { phone, city, resume } = user;
        console.log("resume data", profiledetail);
    
        // Check if profile is incomplete
        if (
            !phone || 
            !city || 
            !resume || 
            !profiledetail || 
            profiledetail.Education.length === 0 || 
            (profiledetail.Job.length === 0 && profiledetail.Internship.length === 0) || 
            profiledetail.Skills.length === 0 || 
            !profiledetail.AddressDetail || profiledetail?.AddressDetail?.length === 0 || !profiledetail.IdentityDetails || profiledetail?.IdentityDetails?.length === 0
        ) {
            return '/profile';
        }
        
        // Check if resume is missing
        if ((phone || city) && !resume) {
            return '/profile';
        }
    
        // Only proceed if we have valid data
        if (!data || !data.profile || !data.opportunityType || !data.employer?.organisationName || !data._id) {
            console.error("Missing required data for URL generation");
            return '/profile'; // Fallback to profile page if data is incomplete
        }
    
        try {
            // Sanitize profile
            const safeProfile = data.profile
                .toLowerCase()
                .replace(/[^a-z0-9\s-]/g, "")
                .replace(/\s+/g, "-")
                .replace(/-+/g, "-")
                .trim();
                
            // Sanitize opportunity type
            const safeOpportunityType = data.opportunityType
                .toLowerCase()
                .replace(/[^a-z0-9\s-]/g, "")
                .replace(/\s+/g, "-")
                .replace(/-+/g, "-")
                .trim();
                
            // Sanitize organization name
            const safeOrgName = data.employer.organisationName
                .toLowerCase()
                .replace(/[^a-z0-9\s-]/g, "")
                .replace(/\s+/g, "-")
                .replace(/-+/g, "-")
                .trim();
                
            // Generate job ID with proper sanitization
            const jobId = `${safeProfile}-${safeOpportunityType}-at-${safeOrgName}_${data._id}`;
            
            // Check credits and return appropriate URL
            if ((data.opportunityType === "Job" && connectCredit < 8) ||
                (data.opportunityType === "Hourly" && connectCredit < 4)) {
                return null; // Will trigger modal instead
            }
            
            return `/coverletterpage/${jobId}`;
        } catch (error) {
            console.error("Error generating URL:", error);
            return '/profile'; // Fallback to profile page in case of errors
        }
    }, [data, user, profiledetail, connectCredit]);
    
    const handleClick = (e) => {
        e.preventDefault();
        
        // If URL is null, show modal
        if (!generateUrl) {
            setShowModal(true);
            return;
        }
        
        // Open in new tab
        window.open(generateUrl, "_blank");
    };

    return (
        <a
            href={generateUrl} // Use # as fallback if generateUrl is null
            rel="noreferrer"
            target="_blank"
            className="btn btnapply"
            style={{
                width: "100px",
                marginLeft: "20px",
            }}
            onClick={handleClick}
        >
            Apply Now
        </a>
    );
};

export default ApplyButton;